define("insights-web/templates/components/count-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+DNgoSt8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"count-display \",[30,[36,1],[[35,0],\"time-widget\"],null]]]],[15,5,[31,[\"background:\",[34,2]]]],[12],[2,\"\\n\"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isTime\",\"if\",\"alertColor\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/count-display.hbs"
    }
  });
});