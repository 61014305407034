define("insights-web/templates/realtime-debug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "huqXyHkr",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"realtime-debug-view\"]}",
    "meta": {
      "moduleName": "insights-web/templates/realtime-debug.hbs"
    }
  });
});