define("insights-web/controllers/call-flow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    requestService: Ember.inject.service(),
    selectedQueues: [],
    queueIds: [],
    interval: "",
    callFlowData: [['From', 'To', 'Conversation Count']],
    queryInProgress: false,
    error: null,
    hasResults: false,
    options: {
      height: 500,
      sankey: {
        node: {
          nodePadding: 60,
          interactivity: true,
          width: 20
        },
        iterations: 64
      },
      animation: {
        startup: true,
        easing: 'inAndOut'
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set("selectedQueues", []);
    },
    actions: {
      handleChartLoaded: function handleChartLoaded(chart) {
        google.visualization.events.addListener(chart, 'select', selectHandler);
        var self = this;
        function selectHandler() {
          try {
            var callFlowMap = self.get("callFlowMap");
            var selection = chart.getSelection();
            if (!selection) {
              return;
            }
            var nodeName = selection[0].name;
            var callsOut = [];
            var node = callFlowMap[nodeName];
            var outKeys = Object.keys(node);
            for (var keyIndex in outKeys) {
              var key = outKeys[keyIndex];
              if (node[key]) {
                callsOut.push({
                  destination: key,
                  conversations: node[key].conversations
                });
              }
            }
            self.set("selectedNode", nodeName);
            self.set("callsOut", callsOut);
            Ember.Logger.log("to");
            Ember.Logger.log(node);
          } catch (ex) {
            Ember.Logger.log(ex);
          }
        }
      },
      executeQuery: function executeQuery() {
        this.set("hasResults", false);
        while (this.callFlowData.length > 1) {
          this.callFlowData.removeAt(1);
        }
        var queues = this.get("queueIds");
        var interval = this.get("interval");
        var queueIds = [];
        for (var x = 0; x < queues.length; x++) {
          queueIds.push(queues[x]);
        }
        var query = {
          query: {
            "interval": interval
          },
          meta: {
            queues: queueIds
          }
        };
        Ember.Logger.log(query);
        var self = this;
        this.set("queryInProgress", true);
        this.get("requestService").postResource('/execute/v1/callflow', query).then(function (data) {
          for (var _x = 1; _x < data.data.length; _x++) {
            self.callFlowData.pushObject(data.data[_x]);
          }
          self.set("callFlowMap", data.flowMap);

          //self.callFlowData.pushObject(["0 - customer","1 - ivr",83]); ///,["1 - ivr","2 - acd",83],["2 - acd","3 - agent",99],["0 - ivr","1 - customer",16],["1 - customer","2 - acd",16]]);
          self.set("queryInProgress", false);
          self.set("hasResults", true);
        }).catch(function (err) {
          self.set("queryInProgress", false);
          var errormessage = JSON.stringify(err, null, " ");
          if (err.error) {
            errormessage = err.error;
          }
          self.set("error", errormessage);
          Ember.Logger.error(err);
        });
        this.set("queryInProgress", true);
      }
    }
  });
});