define("insights-web/templates/components/query-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "K01oZoP6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"panel\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-heading\"],[12],[2,\"\\n    \"],[1,[34,0]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"panel-description\"],[12],[1,[34,1]],[13],[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"title\",\"description\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/query-panel.hbs"
    }
  });
});