define("insights-web/templates/components/select-box-advanced", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "oC8Ypkq/",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"select\"],[14,0,\"form-control \"],[15,\"onchange\",[30,[36,5],[[32,0],\"selectItem\"],[[\"value\"],[\"target.value\"]]]],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"     \"],[10,\"option\"],[15,2,[30,[36,1],[[32,1],[35,0]],null]],[15,\"selected\",[30,[36,3],[[35,2],[30,[36,1],[[32,1],[35,0]],null]],null]],[12],[1,[30,[36,1],[[32,1],[35,4]],null]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\" \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"idKey\",\"getprop\",\"selectedItem\",\"eq\",\"nameKey\",\"action\",\"items\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/select-box-advanced.hbs"
    }
  });
});