define("insights-web/components/configuration/static-text-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    featuretoggleService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.set("dashboardplusEnabled", this.featuretoggleService.featureEnabled("dashboardplus"));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (!this.editWidget.scrollSpeed) {
        this.set('editWidget.scrollSpeed', 5);
      }
    }
  });
});