define("insights-web/utils/colorDefaults", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var colors = {
    defaultText: "rgb(51, 51, 51)",
    defaultBackground: "#f8f8f8",
    defaultDarkText: "white",
    defaultDarkBackground: "#444A52"
  };
  var _default = _exports.default = colors;
});