define("insights-web/components/debug-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    queueService: Ember.inject.service(),
    realtimeService: Ember.inject.service(),
    requestService: Ember.inject.service(),
    purecloud: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    actions: {
      downloadDebug: function downloadDebug() {
        var _this = this;
        this.get("purecloud").getRequest("/api/v2/date").then(function (date) {
          _this.get("requestService").getMe().then(function (me) {
            var data = {
              currentTime: moment().format(),
              pollingStartTime: _this.get('realtimeService').get("startPollingTime"),
              lastWebSocketNotification: _this.get('realtimeService').get("lastWebSocketNotification"),
              queues: _this.get("queueService").queueList,
              queueUsers: _this.get('realtimeService').get("queueUsers"),
              dashboardConfig: _this.get("dashboardConfiguration"),
              userPresence: _this.get('realtimeService').get("userPresence"),
              userRoutingStatus: _this.get('realtimeService').get("userRoutingStatus"),
              queueData: _this.get('realtimeService').get("queueData"),
              observationData: _this.get('realtimeService').get("observationData"),
              lastUpdateTimes: _this.get('realtimeService').get("lastUpdateTimes"),
              notificationHistory: _this.get('realtimeService').get("notificationHistory"),
              latestErrors: _this.get('requestService').get("recentErrors"),
              campaignStats: _this.get('requestService').get("campaignStats"),
              agentData: _this.get('requestService').get("agentData"),
              queueAggregates: _this.get('realtimeService').get("queueAggregateHistory"),
              me: me,
              date: date
            };

            //
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(data)));
            element.setAttribute('download', "pureinsightsdebugdata.json");
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          });
        });
      }
    }
  });
});