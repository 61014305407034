define("insights-web/components/dnis-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set("description", "Filter based on calls matching a specific " + this.get("title") + ".");
    }
  });
});