define("insights-web/components/agent-stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    queueService: Ember.inject.service(),
    statCalculation: Ember.inject.service(),
    realtimeService: Ember.inject.service(),
    statusService: Ember.inject.service(),
    timeDiff: Ember.inject.service(),
    expander: [],
    _stats: [],
    data: [],
    headers: [],
    init: function init() {
      this._super.apply(this, arguments);
      this.get("statusService");
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.get("realtimeService").get("userData");
      this.get("realtimeService").get("observationData");
      this._stats = this.configuration.stats;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.updateTimers();
      this._loadData();
      Ember.run.later(this, function () {
        this._resizeScroller();
      }, 500);
    },
    updateTimers: function updateTimers() {
      Ember.run.later(this, function () {
        this.updateTimers();
      }, 1000); //every second

      this.updateTimersImpl();
    },
    updateTimersImpl: function updateTimersImpl() {
      var data = this.get("data");
      if (data) {
        for (var x = 0; x < data.length; x++) {
          var row = data[x];
          for (var y = 0; y < row.stats.length; y++) {
            var stat = row.stats[y];
            if (stat.timeSince) {
              var timeDiff = moment().diff(this.timeDiff.skewAdjustedTime(stat.timeSince));
              Ember.set(this.data[x].stats[y], "value", moment.utc(timeDiff).format("HH:mm:ss")); //.fromNow();
            }
          }
        }
      }
    },
    queueNameObserver: Ember.observer('configuration', 'configuration.queueIds', 'configuration.queueIds.@each', function () {
      this.loadQueueIds();
    }),
    _loadData: function _loadData() {
      var _this = this;
      this.set('errorMessage', null);
      var queueIds = [];
      if (!this.configuration.fontsize) {
        this.configuration.fontsize = "18";
      }
      this.configuration.queueIds.forEach(function (queueId) {
        queueIds.pushObject(queueId);
      });
      var realtimeService = this.get("realtimeService");
      var _data = [];
      if (queueIds === null) {
        return;
      }
      var queueUsers = [];
      for (var y = 0; y < queueIds.length; y++) {
        var queueId = queueIds[y];

        //let queueUsers = queueUsers.concat(realtimeService.queueUsers[queueId]);
        if (realtimeService.queueUsers) {
          if (realtimeService.queueUsers[queueId]) {
            realtimeService.queueUsers[queueId].forEach(function (user) {
              if (_this.configuration.showInactiveAgents !== true && user.joined == false) {
                return;
              }
              if (queueUsers.indexOf(user.id) === -1) {
                queueUsers.push(user);
              }
            });
            if (queueUsers.length === 0) {
              this.set('errorMessage', "No users configured for queue");
            }
          } else {
            this.set('errorMessage', "User status information not found for queue");
          }
        } else {
          this.set('errorMessage', "No user status information found");
        }
      }
      queueUsers.forEach(function (user) {
        var shouldInclude = true;
        var rowData = {
          isExpanded: false,
          userId: user.id,
          stats: [{
            value: user.name
          }]
        };
        if (_this.configuration.showAgentPicture) {
          rowData.stats[0].image = realtimeService.userImages[user.id];
        }
        if (_this._stats.length === 0) {
          _this._stats = ["agent_status", "agent_time_in_status"];
        }
        for (var x = 0; x < _this._stats.length; x++) {
          var statKey = _this._stats[x];
          var stat = {
            column: statKey //statCalculation.getStatDisplayName(statKey),
          };
          if (statKey === "agent_status") {
            var value = "";
            var statusKey = "";
            if (realtimeService.userRoutingStatus[user.id]) {
              statusKey = realtimeService.userRoutingStatus[user.id].status;
              value = statusKey;
              rowData.stats[0].cssClass = "ON_QUEUE";
              if (value === "OFF_QUEUE" && !_this.configuration.showOffQueue) {
                shouldInclude = false;
              }
              if (value === "OFF_QUEUE" && realtimeService.userPresence[user.id]) {
                value = realtimeService.userPresence[user.id].presenceDefinition.systemPresence;
                rowData.stats[0].cssClass = realtimeService.userPresence[user.id].presenceDefinition.systemPresence;

                //Weird edge case, off queue and idle, shows as away in purecloud
                if (value && value.toLocaleLowerCase() === "idle") {
                  value = "Away";
                  rowData.stats[0].cssClass = "Away";
                } else {
                  var subValue = _this.get("statusService").getStatusLabel(realtimeService.userPresence[user.id].presenceDefinition.id);
                  if (subValue) {
                    value = subValue;
                  }
                }
              }
              if (rowData.stats[0].cssClass === "ON_QUEUE" && _this.configuration.useCustomQueueColors) {
                if (value === "NOT_RESPONDING") {
                  rowData.stats[0].customColor = _this.configuration.notRespondingColor;
                } else if (value === "IDLE") {
                  rowData.stats[0].customColor = _this.configuration.idleColor;
                } else if (value === "INTERACTING") {
                  rowData.stats[0].customColor = _this.configuration.interactingColor;
                }
              }
            } else if (!_this.configuration.showOffQueue) {
              shouldInclude = false;
            } else if (realtimeService.userPresence[user.id]) {
              value = realtimeService.userPresence[user.id].presenceDefinition.systemPresence;
              rowData.stats[0].cssClass = realtimeService.userPresence[user.id].presenceDefinition.systemPresence;
            }
            if (realtimeService.userPresence[user.id] && realtimeService.userPresence[user.id].presenceDefinition.systemPresence === "Offline") {
              value = null;
            }
            if (!value) {
              value = "OFFLINE";
              rowData.stats[0].cssClass = "Offline";
            }
            if (value) {
              value = _this.get("statusService").getRoutingStatusLabel(value);
            }
            if (realtimeService.userIsOOO[user.id]) {
              rowData.stats[0].cssClass = "OOO";
              value = "Out of Office";
            }
            if (value === "Offline" && !_this.configuration.showOffline) {
              shouldInclude = false;
            }

            // if(['ON_QUEUE','Away','Meal','Training','Break','Busy','Meeting','OOO','Offline','Available', 'Idle'].indexOf(rowData.stats[0].cssClass) === -1){
            //     //This is here to track down an issue where we are getting a unknown css class value
            //     value = value + ` (${rowData.stats[0].cssClass})`;
            // }

            if (statusKey == "INTERACTING" && realtimeService.userConversations[user.id] && realtimeService.userConversations[user.id].isAcw) {
              value = "ACW";
            }
            stat.value = value;
          } else if (statKey === "agent_time_in_status") {
            if (realtimeService.userRoutingStatus[user.id]) {
              stat.timeSince = realtimeService.userRoutingStatus[user.id].startTime;
              if (realtimeService.userRoutingStatus[user.id].status === "OFF_QUEUE" && realtimeService.userPresence[user.id]) {
                stat.timeSince = realtimeService.userPresence[user.id].modifiedDate;
              }
            }
            var _statusKey = "";
            if (realtimeService.userRoutingStatus[user.id]) {
              _statusKey = realtimeService.userRoutingStatus[user.id].status;
            }
            if (_statusKey == "INTERACTING" && realtimeService.userConversations[user.id] && realtimeService.userConversations[user.id].isAcw) {
              stat.timeSince = realtimeService.userConversations[user.id].acwStartTime;
            }
          } else if (statKey === "longest_active_conversation") {
            if (realtimeService.userConversations[user.id] && realtimeService.userConversations[user.id].longestActiveStart) {
              stat.timeSince = realtimeService.userConversations[user.id].longestActiveStart;
            } else {
              stat.value = "-";
            }
          } else if (statKey === "conversations_on_hold") {
            if (realtimeService.userConversations[user.id]) {
              stat.value = realtimeService.userConversations[user.id].conversationsOnHold;
            } else {
              stat.value = "0";
            }
          } else if (statKey === "current_conversation_count") {
            if (realtimeService.userConversations[user.id]) {
              stat.value = realtimeService.userConversations[user.id].conversationCount;
            } else {
              stat.value = "0";
            }
          } else {
            stat.value = _this.get("statCalculation").getStatValue(_this.configuration.queueIds, statKey, "", "", user.id).data;
          }
          rowData.stats.push(stat);
        }
        if (_data.findBy('userId', user.id)) {
          shouldInclude = false;
        }
        if (shouldInclude) {
          _data.push(rowData);
        }
      });
      var headers = [];
      headers.push("User");
      for (var x = 0; x < this._stats.length; x++) {
        var statKey = this._stats[x];
        headers.push(this.get("statCalculation").getStatDisplayName(statKey));
        // headers.push(statKey);
      }
      if (this.configuration.sortBy && this.configuration.sortBy.toLowerCase() === "status") {
        _data.sort(function (a, b) {
          if (a.stats[1].value === b.stats[1].value) {
            return a.stats[0].value.localeCompare(b.stats[0].value);
          } else if (a.stats[1].value > b.stats[1].value) {
            return 1;
          }
          return -1;
        });
      } else if (this.configuration.sortBy && this.configuration.sortBy.toLowerCase() === "duration") {
        _data.sort(function (a, b) {
          var aVal = moment().diff(moment.utc(a.stats[2].timeSince));
          var bVal = moment().diff(moment.utc(b.stats[2].timeSince));
          return bVal - aVal;
        });
      } else {
        //sort by name
        _data.sort(function (a, b) {
          return a.stats[0].value.localeCompare(b.stats[0].value);
        });
      }
      this.set("headers", headers);
      this.set("data", _data);
      this.updateTimersImpl();
      this._resizeScroller();
    },
    _resizeScroller: function _resizeScroller() {
      var parentHeight = this.$().parents('.grid-stack-item-content').height();
      this.parentHeight = parentHeight;
      if (parentHeight > 10) {
        this.$().children(".scroll-table-container").height(parentHeight);
      }
    },
    dataObserver: Ember.observer('realtimeService.queueUsers', 'realtimeService.userPresence', 'realtimeService.userRoutingStatus', 'realtimeService.userIsOOO', 'realtimeService.agentData', 'realtimeService.userConversations', function () {
      this._loadData();
    }),
    actions: {
      toggleExpand: function toggleExpand(index) {
        this.expander.arrayContentWillChange(index);
        this.expander[index] = !this.expander[index];
        Ember.set(this.data[index], 'isExpanded', this.expander[index]);
        ///    Ember.set(this.expander, `total.${year}`, data.total);
      }
    }
  });
});