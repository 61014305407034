define("insights-web/routes/debug-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    purecloud: Ember.inject.service(),
    model: function model() {
      return {
        query: "",
        results: null
      };
    },
    actions: {
      run: function run() {
        var model = this.get("controller.model");
        try {
          var query = JSON.parse(model.query);
          if (query.method === "GET") {
            this.get("purecloud").getRequest(query.url).then(function (res) {
              Ember.set(model, "results", JSON.stringify(res, null, 2));
            }).catch(function (ex) {
              Ember.set(model, "results", JSON.stringify(ex, null, 2));
            });
          } else {
            this.get("purecloud").post(query.url, query.data).then(function (res) {
              Ember.set(model, "results", JSON.stringify(res, null, 2));
            }).catch(function (ex) {
              Ember.set(model, "results", JSON.stringify(ex, null, 2));
            });
          }
        } catch (ex) {
          Ember.set(model, "results", ex);
        }
      }
    }
  });
  /*
  {
      url: "/api/v2/analytics/conversations/details/query",
      data: {
          "interval": "2018-09-28T00:00:00.000Z/2018-09-28T14:49:00.000Z",
          "order": "asc",
          "orderBy": "conversationStart",
          "paging": {
              "pageSize": 100,
              "pageNumber": 1
          },
          "segmentFilters": [
          {
              "type": "or",
              "predicates": [
                  {
                  "type": "dimension",
                  "dimension": "queueId",
                  "operator": "matches",
                  "value": "e3c9259a-517d-48dc-8158-26ea50c915d4"
                  }
              ]
          }
          ],
          "aggregations": [
              {
              "type": "termFrequency",
              "dimension": "wrapUpCode",
              "size": "10"
              }
          ]
      }
  }
  */
});