define("insights-web/utils/agentstattypes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var agentstattypes = [{
    "key": "agent_status",
    "text": "Status",
    "metrics": [""]
  }, {
    "key": "agent_time_in_status",
    "text": "Time In Status",
    "metrics": [""]
  }, {
    "key": "current_conversation_count",
    "text": "Active Conversations",
    "metrics": [""]
  }, {
    "key": "longest_active_conversation",
    "text": "Longest Active Conversation",
    "metrics": [""]
  }, {
    "key": "agent_answered",
    "text": "Answered",
    "metrics": ["tAnswered"]
  }, {
    "key": "agent_hold_time",
    "text": "Hold Time",
    "metrics": ["tHeldComplete"]
  }, {
    "key": "agent_talk_time",
    "text": "Talk Time",
    "metrics": ["tTalkComplete"]
  }, {
    "key": "agent_avg_hold_time",
    "text": "Avg Hold Time",
    "metrics": ["tHeldComplete"]
  }, {
    "key": "agent_avg_talk_time",
    "text": "Avg Talk Time",
    "metrics": ["tTalkComplete"]
  }, {
    "key": "agent_average_handle",
    "text": "Avg Handle Time",
    "metrics": ["tHandle"]
  }, {
    "key": "agent_average_acw",
    "text": "Avg ACW Time",
    "metrics": ["tAcw"]
  }, {
    "key": "agent_transfer",
    "text": "Transfer Count",
    "metrics": ["nTransferred"]
  }, {
    "key": "outbound_calls",
    "text": "Outbound Calls",
    "metrics": ["nOutbound"]
  }];
  var _default = _exports.default = agentstattypes;
});