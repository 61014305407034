define("insights-web/services/status-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    purecloud: Ember.inject.service(),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.get("purecloud").getRequest("/api/v2/presence/definitions").then(function (definations) {
        var defMap = {};
        definations.entities.forEach(function (e) {
          defMap[e.id] = e;
        });
        _this.set("definitions", defMap);
      }).catch(function () {
        _this.get("purecloud").getPresenceApi().getPresencedefinitions(1, 250).then(function (definations) {
          var defMap = {};
          definations.entities.forEach(function (e) {
            defMap[e.id] = e;
          });
          _this.set("definitions", defMap);
        });
      });
      this.get("purecloud").getPresenceApi().getSystempresences().then(function (definations) {
        var defMap = {};
        definations.entities.forEach(function (e) {
          defMap[e.name] = e.id;
        });
        _this.set("routingdefinitions", defMap);
      });
    },
    getStatusLabel: function getStatusLabel(id) {
      var definitions = this.get('definitions');
      if (!definitions) {
        return null;
      }
      var presenceDefinition = definitions[id];
      if (!presenceDefinition || !presenceDefinition.languageLabels) {
        return null;
      }
      var browserLanguages = navigator.languages;
      for (var x = 0; x < browserLanguages.length; x++) {
        var language = browserLanguages[x].replace("-", "_");
        if (presenceDefinition.languageLabels[language]) {
          return presenceDefinition.languageLabels[language];
        }
      }
      return null;
    },
    getRoutingStatusLabel: function getRoutingStatusLabel(routingStatus) {
      var routingDefinitions = this.get('routingdefinitions');
      if (routingDefinitions) {
        var routingDefinitionId = routingDefinitions[routingStatus];
        var label = this.getStatusLabel(routingDefinitionId);
        if (label) {
          return label;
        }
      }
      var value = routingStatus.toLowerCase();
      value = value[0].toUpperCase() + value.substring(1);
      value = value.replace("_", " ");
      return value;
    }
  });
});