define("insights-web/controllers/client-app-install", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    purecloud: Ember.inject.service(),
    requestService: Ember.inject.service(),
    groups: [],
    hasPermissions: true,
    canInstall: Ember.computed('hasPermissions', 'groups', 'groups.@each', function () {
      return this.get("hasPermissions") === true && this.get("groups").length > 0;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var self = this;
      this.get("requestService").getResource('/purecloud/myauthorization').then(function (authorization) {
        self.set("hasPermissions", authorization.hasIntegrationInstall);
      });
    },
    actions: {
      searchTask: function searchTask(term) {
        var search = {
          "pageSize": 25,
          "pageNumber": 1,
          "types": ["groups"],
          "query": [{
            "type": "TERM",
            "fields": ["name"],
            "value": term,
            "operator": "AND"
          }]
        };
        return this.get("purecloud").post("/api/v2/search?profile=false", search).then(function (results) {
          return results.results;
        });
      },
      install: function install() {
        var self = this;
        var groupIds = [];
        this.get("groups").forEach(function (group) {
          groupIds.push(group.id);
        });
        return this.get("requestService").postResource('/purecloud/installclientapp', {
          groups: groupIds
        }).then(function () {
          self.set("success", "Application was successfully installed and can be found in Performance > PureInsights Dashboard.  You will need to refresh your PureCloud client to see the changes.");
          self.set("error", null);
        }).catch(function (err) {
          console.log(err);
          self.set("error", "An error occurred installing the app. " + err.responseText);
          self.set("success", null);
        });
      }
    }
  });
});