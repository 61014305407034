define("insights-web/templates/components/time-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZjwdxrXU",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],null,[[\"title\",\"description\"],[\"Time Range\",\"Select the time range for the result set.\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,5],null,[[\"idKey\",\"nameKey\",\"items\",\"selectedItem\"],[\"value\",\"text\",[35,4],[35,3]]]]],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[35,3],\"custom\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"label\"],[14,0,\"custom-time\"],[12],[2,\"Start Time\"],[13],[2,\"\\n\\n      \"],[1,[30,[36,1],null,[[\"date\",\"format\"],[[35,0],\"YYYY-MM-DD h:mm:ss a\"]]]],[2,\"\\n      \"],[10,\"label\"],[14,0,\"custom-time\"],[12],[2,\"End Time\"],[13],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"date\",\"format\"],[[35,2],\"YYYY-MM-DD h:mm:ss a\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"startDate\",\"bs-datetimepicker\",\"endDate\",\"selectedRangeType\",\"rangeTypes\",\"select-box-advanced\",\"eq\",\"if\",\"query-panel\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/time-range.hbs"
    }
  });
});