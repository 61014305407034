define("insights-web/services/analytics-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    logEvent: function logEvent(action, label) {
      // if(typeof ga !== 'undefined' && ga && config.analyticsTrackingId){
      //     ga('send', 'event', "Webapp", action, label);
      // }
    },
    logCallDetailReport: function logCallDetailReport() {
      this.get("logEvent")("Call Detail Report");
    },
    logWidgetConfigure: function logWidgetConfigure() {
      this.get("logEvent")("Widget Configuration");
    },
    exception: function exception(ex) {
      // if(typeof ga !== 'undefined' && ga && config.analyticsTrackingId){
      //   ga('send', 'exception', {
      //     'exDescription': JSON.stringify(ex),
      //     'exFatal': false
      //   });
      // }
    }
  });
});