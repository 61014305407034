define("insights-web/helpers/formattimezone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatTimezone = formatTimezone;
  function formatTimezone(params) {
    var value = params[0];
    var split = value.split('/');
    if (split.length > 1) {
      value = split[1];
    }
    value = value.replace(/_/g, ' ');
    return value;
  }
  var _default = _exports.default = Ember.Helper.helper(formatTimezone);
});