define("insights-web/services/error-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    recentErrors: [],
    init: function init() {
      // super.init(...arguments);
      this._super.apply(this, arguments);
    },
    error: function error(message) {
      this.logError(message, null);
    },
    logError: function logError(message, correlationId) {
      if (!this.get("recentErrors")) {
        this.set("recentErrors", []);
      }
      this.recentErrors.pushObject({
        time: moment().format(),
        message: message,
        correlationId: correlationId
      });
      if (this.recentErrors.length > 50) {
        this.recentErrors.removeAt(0);
      }
    }
  });
});