define("insights-web/helpers/statgridextracols", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.statGridExtraCols = statGridExtraCols;
  function statGridExtraCols(params /*, hash*/) {
    var index = params[0];
    if (index === 0) {
      return "hidden";
    }
    if (index <= 3) {
      return "hidden-md hidden-sm hidden-lg";
    }
    if (index < 6) {
      return "hidden-md hidden-lg";
    }
    return "hidden-lg";
  }
  var _default = _exports.default = Ember.Helper.helper(statGridExtraCols);
});