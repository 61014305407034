define("insights-web/components/realtime-debug-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    queueService: Ember.inject.service(),
    realtimeService: Ember.inject.service(),
    requestService: Ember.inject.service(),
    purecloud: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    dashboardString: "",
    wrapups: [],
    queueNameObserver: Ember.observer('queueService.queueList', 'queueService.@each', function () {
      this.set("queues", this.get("queueService").queueList);
    }),
    dashboardConfigurations: Ember.observer('realtimeService.dashboardConfiguration', function () {
      Ember.Logger.log("got dashbosed");
      this.set("dashboardString", JSON.stringify(this.get('realtimeService').get("dashboardConfiguration"), null, " "));
      //return this.get('realtimeService').get("dashboardConfiguration");
    }),
    observationData: Ember.computed('realtimeService.observationData', function () {
      return JSON.stringify(this.get('realtimeService').get("observationData"), null, " ");
    }),
    userRoutingStatus: Ember.computed('realtimeService.userRoutingStatus', function () {
      return JSON.stringify(this.get('realtimeService').get("userRoutingStatus"), null, " ");
    }),
    queueUsers: Ember.computed('realtimeService.queueUsers', function () {
      return JSON.stringify(this.get('realtimeService').get("queueUsers"), null, " ");
    }),
    userPresence: Ember.computed('realtimeService.userPresence', function () {
      return JSON.stringify(this.get('realtimeService').get("userPresence"), null, " ");
    }),
    queueData: Ember.computed('realtimeService.queueData', function () {
      return JSON.stringify(this.get('realtimeService').get("queueData"), null, " ");
    }),
    wrapupObserver: Ember.observer('wrapups', 'wrapups.@each', function () {
      this.set("wrapupData", JSON.stringify(this.get("wrapups"), null, " "));
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.get("queueService");
      this.get("wrapups");
      this.set("queues", this.get("queueService").queueList);
      this.get('realtimeService').get("userData");
      var realtimeService = this.get("realtimeService");
      realtimeService.get("dashboardConfiguration");
      this.set("userId", this.get("purecloud").getUserId());
      var self = this;
      this.get("realtimeService").getDashboards().then(function (dashboards) {
        self.set("dashboardConfiguration", dashboards);
        self.set("dashboardString", JSON.stringify(dashboards, null, " "));
        dashboards.dashboards.forEach(function (dashboard) {
          dashboard.widgets.forEach(function (widget) {
            if (widget.widgetType === "topwrapups") {
              var end = moment().endOf("day");
              var start = moment().startOf("day");
              realtimeService.getConversationSummary("wrapUpCode", widget.count, start, end, widget.queueIds, 100).then(function (values) {
                self.wrapups.pushObject({
                  title: widget.title,
                  values: values
                });
              });
            }
          });
        });
      });
    },
    actions: {
      copyToClipboard: function copyToClipboard() {
        var _this = this;
        this.get("purecloud").getRequest("/api/v2/date").then(function (date) {
          _this.get("requestService").getMe().then(function (me) {
            var data = {
              currentTime: moment().format(),
              pollingStartTime: _this.get('realtimeService').get("startPollingTime"),
              lastWebSocketNotification: _this.get('realtimeService').get("lastWebSocketNotification"),
              queues: _this.get("queueService").queueList,
              queueUsers: _this.get('realtimeService').get("queueUsers"),
              dashboardConfig: _this.get("dashboardConfiguration"),
              userPresence: _this.get('realtimeService').get("userPresence"),
              userRoutingStatus: _this.get('realtimeService').get("userRoutingStatus"),
              queueData: _this.get('realtimeService').get("queueData"),
              observationData: _this.get('realtimeService').get("observationData"),
              lastUpdateTimes: _this.get('realtimeService').get("lastUpdateTimes"),
              notificationHistory: _this.get('realtimeService').get("notificationHistory"),
              latestErrors: _this.get('requestService').get("recentErrors"),
              campaignStats: _this.get('requestService').get("campaignStats"),
              agentData: _this.get('requestService').get("agentData"),
              queueAggregates: _this.get('realtimeService').get("queueAggregateHistory"),
              me: me,
              date: date
            };

            //
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(data)));
            element.setAttribute('download', "pureinsightsdebugdata.json");
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          });
        });
      }
    }
  });
});