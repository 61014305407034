define("insights-web/components/queue-interaction-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    queueService: Ember.inject.service(),
    statCalculation: Ember.inject.service(),
    realtimeService: Ember.inject.service(),
    expander: [],
    _queueIds: [],
    _stats: null,
    data: null,
    isLoading: true,
    headers: [],
    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      this.get("realtimeService").get("queueData");
      this.get("realtimeService").get("observationData");
      this._queueIds.clear();
      this.configuration.queueIds.forEach(function (queueId) {
        _this._queueIds.pushObject(queueId);
      });
      this.updateTimers();
    },
    _loadData: function _loadData() {
      var statCalculation = this.get("statCalculation");
      var data = statCalculation.getStatValue(this._queueIds, "calls_waiting_list", null, this.configuration.mediaType);
      data.data.forEach(function (d) {
        var diff = moment() - moment(d.observationDate);
        d.timeWaiting = moment.utc(diff).format("HH:mm:ss");
      });
      this.set("calls", data.data);
    },
    dataObserver: Ember.observer('realtimeService.queueData', 'realtimeService.observationData', function () {
      this._loadData();
    }),
    updateTimers: function updateTimers() {
      Ember.run.later(this, function () {
        this.updateTimers();
      }, 1000); //every second

      this.updateTimersImpl();
    },
    updateTimersImpl: function updateTimersImpl() {
      var data = this.get("calls");
      if (data) {
        for (var x = 0; x < data.length; x++) {
          var row = data[x];
          var diff = moment() - moment(row.observationDate);
          Ember.set(row, 'timeWaiting', moment.utc(diff).format("HH:mm:ss"));
        }
      }
    }
  });
});