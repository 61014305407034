define("insights-web/utils/widgettypes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var widgettypes = [{
    "key": "statistic",
    "text": "Statistic",
    "defaultSize": {
      "width": 3,
      "height": 3
    }
  }, {
    "key": "chart",
    "text": "Chart",
    "defaultSize": {
      "width": 3,
      "height": 4
    }
  }, {
    "key": "observationchart",
    "text": "Queue Observation Chart",
    "defaultSize": {
      "width": 3,
      "height": 4
    },
    "feature": "observationchart"
  }, {
    "key": "agentstats",
    "text": "Agent Stat Grid",
    "defaultSize": {
      "width": 3,
      "height": 4
    }
  }, {
    "key": "queuestats",
    "text": "Queue Stat Grid",
    "defaultSize": {
      "width": 3,
      "height": 4
    }
  },
  // {
  //     "key": "queuecalls",
  //     "text": "Queue Calls",
  //     "defaultSize":{
  //         "width":3,
  //         "height":4,
  //     }
  // },
  {
    "key": "topwrapups",
    "text": "Top Wrapup Codes",
    "defaultSize": {
      "width": 3,
      "height": 4
    }
  }, {
    "key": "image",
    "text": "Image",
    "defaultSize": {
      "width": 4,
      "height": 2
    }
  }, {
    "key": "clock",
    "text": "Clock",
    "defaultSize": {
      "width": 2,
      "height": 2
    }
  }, {
    "key": "webframe",
    "text": "Embedded Web Page",
    "defaultSize": {
      "width": 3,
      "height": 4
    }
  }, {
    "key": "statictext",
    "text": "Static Text",
    "defaultSize": {
      "width": 6,
      "height": 1
    },
    "feature": "dashboardplus"
  }];
  var _default = _exports.default = widgettypes;
});