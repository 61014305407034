define("insights-web/templates/components/wrapup-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "qJlK9umM",
    "block": "{\"symbols\":[\"wrapup\"],\"statements\":[[10,\"span\"],[14,0,\"h3\"],[15,5,[31,[\"font-size:\",[34,4]]]],[12],[2,\"\\n    \"],[1,[35,5,[\"title\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,6],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"label\"],[14,0,\"text-warning\"],[12],[1,[34,3]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[10,\"table\"],[14,0,\"table table-stripeds table-condensed wrapups\"],[12],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n            \"],[1,[32,1,[\"code\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n            \"],[1,[32,1,[\"value\"]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"wrapups\",\"-track-array\",\"each\",\"wrapupwarning\",\"titlesize\",\"configuration\",\"if\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/wrapup-widget.hbs"
    }
  });
});