define("insights-web/templates/components/configuration/observation-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Aq0xW+Iz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-1\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"Title\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n        \"],[1,[30,[36,6],null,[[\"class\",\"value\"],[\"form-control\",[35,3,[\"title\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-md-3\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"Hours of Data to Show\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-2\"],[12],[2,\"\\n        \"],[1,[30,[36,6],null,[[\"class\",\"type\",\"value\",\"min\",\"max\"],[\"form-control\",\"number\",[35,3,[\"duration\"]],\"1\",\"24\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,7],null,[[\"title\",\"description\"],[\"Agent Presence Queue\",\"Select which queue to use to determine agent presence \"]],[[\"default\"],[{\"statements\":[[1,[30,[36,5],null,[[\"idKey\",\"nameKey\",\"items\",\"selectedItem\"],[\"id\",\"name\",[35,4],[35,3,[\"agentStatusQueue\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,8],null,[[\"selectedQueueIds\",\"title\",\"description\"],[[35,3,[\"queueIds\"]],\"Conversation Count Queues\",\"Select which queue to use for conversations waiting and interacting\"]]]],[2,\"\\n\\n\"],[6,[37,7],null,[[\"title\",\"description\"],[\"Statistics\",\"Select which statistics to display\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,2],null,[[\"availableItems\",\"selectedItems\",\"sortSelected\",\"title\"],[[35,1],[35,0],true,\"\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"selectedStats\",\"availableStats\",\"two-col-selector\",\"editWidget\",\"queues\",\"select-box-advanced\",\"input\",\"query-panel\",\"queue-selector\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/configuration/observation-graph.hbs"
    }
  });
});