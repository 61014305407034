define("insights-web/templates/components/widget-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rz/sSHB8",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[30,[36,1],[[35,0,[\"title\"]],\"https\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"text-danger\"],[12],[2,\"\\n    Image URL must start with https.\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"img\"],[15,\"src\",[34,0,[\"title\"]]],[14,5,\"width:100%\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"configuration\",\"contains\",\"unless\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/widget-image.hbs"
    }
  });
});