define("insights-web/components/time-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    storageService: Ember.inject.service(),
    startDate: moment().startOf('day'),
    endDate: moment().endOf('day'),
    selectedRangeType: "yesterday",
    rangeTypes: [{
      value: "yesterday",
      text: "Yesterday"
    }, {
      value: "today",
      text: "Today"
    }, {
      value: "TW",
      text: "This Week"
    }, {
      value: "LW",
      text: "Last Week"
    }, {
      value: "TM",
      text: "This Month"
    }, {
      value: "LM",
      text: "Last Month"
    }, {
      value: "custom",
      text: "Custom"
    }],
    init: function init() {
      this._super.apply(this, arguments);
      var self = this;
      var storage = this.get("storageService");
      if (storage.sessionStorageGet("selectedTimeRangeType")) {
        this.set("selectedRangeType", storage.sessionStorageGet("selectedTimeRangeType"));
      }
      setTimeout(function () {
        if (self && !(self.get('isDestroyed') || self.get('isDestroying'))) {
          self._computeValue();
        }
      }, 100);
    },
    _computeValue: function _computeValue() {
      var storage = this.get("storageService");
      storage.sessionStorageSet("selectedTimeRangeType", this.get("selectedRangeType"));
      var start = this.get("startDate");
      var end = this.get("endDate");
      var intervalType = this.get("selectedRangeType");
      var value = "";
      switch (intervalType) {
        case "today":
          value = moment().startOf('day').utc().toISOString() + "/" + moment().endOf('day').add(1, 'millisecond').utc().toISOString();
          break;
        case "yesterday":
          value = moment().startOf('day').subtract(1, 'days').utc().toISOString() + "/" + moment().endOf('day').subtract(1, 'days').add(1, 'millisecond').utc().toISOString();
          break;
        case "TW":
          value = moment().startOf('week').utc().toISOString() + "/" + moment().endOf('week').add(1, 'millisecond').utc().toISOString();
          break;
        case "LW":
          value = moment().startOf('week').subtract(1, 'weeks').utc().toISOString() + "/" + moment().endOf('week').subtract(1, 'weeks').add(1, 'millisecond').utc().toISOString();
          break;
        case "TM":
          value = moment().startOf('month').utc().toISOString() + "/" + moment().endOf('month').add(1, 'millisecond').utc().toISOString();
          break;
        case "LM":
          value = moment().startOf('month').subtract(1, 'month').utc().toISOString() + "/" + moment().subtract(1, 'month').endOf('month').add(1, 'millisecond').utc().toISOString();
          break;
        case "custom":
          value = start.toISOString() + "/" + end.toISOString();
          break;
        default:
      }
      this.set("value", value);
    },
    valuesChanged: Ember.observer('startDate', 'endDate', 'selectedRangeType', function () {
      this._computeValue();
    })
  });
});