define("insights-web/components/observation-graph", ["exports", "insights-web/utils/observationGraphStatTypes"], function (_exports, _observationGraphStatTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ColorMap = {
    "oWaiting": "#ff5050",
    "oInteracting": "#0fcf66",
    "oUserRoutingStatuses.INTERACTING": "#0fcf66",
    "oUserRoutingStatuses.IDLE": "#b297c3",
    "oUserRoutingStatuses.NOT_RESPONDING": "#ffc000"
  };
  var _default = _exports.default = Ember.Component.extend({
    chartHeight: "100%",
    // Calls in queue waiting - red - oWaiting
    // Active calls - green  - oUserRoutingStatuses.INTERACTING
    // Agents idle waiting on calls - blue - oUserRoutingStatuses.IDLE
    // Not Responding - purple - oUserRoutingStatuses.NOT_RESPONDING
    observationStatService: Ember.inject.service(),
    getLabel: function getLabel(stat) {
      if (this.get("height") <= 4) {
        return stat.legendText;
      }
      return stat.text;
    },
    createChart: function createChart() {
      var _this = this;
      if (!this || this.isDestroyed) {
        return;
      }
      if (this.get("imgsrc")) {
        return;
      }
      this.set("chartHeight", this.$().parents('.grid-stack-item-content').height() - 55 + "px");
      var self = this;
      var configuration = this.get("configuration");
      var observationStatService = this.get("observationStatService");
      var metrics = [];
      var options = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: []
        }],
        yAxis: [{
          type: 'value'
        }],
        series: []
      };
      var _loop = function _loop() {
        var stat = configuration.stats[x];
        _observationGraphStatTypes.default.forEach(function (type) {
          if (type.key === stat) {
            options.legend.data.push(_this.getLabel(type));
            metrics.push(type.observationMetric);
            options.series.pushObject({
              name: _this.getLabel(type),
              type: 'line',
              stack: 'Total',
              symbol: 'none',
              areaStyle: {
                color: ColorMap[type.observationMetric]
              },
              emphasis: {
                focus: 'series'
              },
              lineStyle: {
                width: 0
              },
              sampling: 'lttb',
              data: [],
              color: ColorMap[type.observationMetric]
            });
          }
        });
      };
      for (var x = configuration.stats.length - 1; x >= 0; x--) {
        _loop();
      }
      var dataSets = observationStatService.getDataTable(configuration.agentStatusQueue, configuration.queueIds, metrics, configuration.duration);
      dataSets.forEach(function (row) {
        options.xAxis[0].data.pushObject(row[0]);
        for (var c = 1; c < row.length; c++) {
          options.series[c - 1].data.pushObject(row[c]);
        }
      });
      var chartDiv = this.$().find("div")[0];
      var myChart = echarts.init(chartDiv, this.get("darkMode") ? 'dark' : null);
      Ember.run.later(function () {
        // use configuration item and data specified to show chart
        myChart.setOption(options);
        myChart.resize();
      }, 500);
    },
    darkModeChanged: Ember.observer('darkMode', function () {
      this.createChart();
    }),
    valuesChanged: Ember.observer('observationStatService.updateFlag', 'observationStatService.stats', function () {
      this.createChart();
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var parentContainer = this.get('parentContainer');
      if (parentContainer && parentContainer.$()) {
        parentContainer.$().off('resizestop');
      }
    },
    didRender: function didRender() {
      var axisUnit = "hour";
      this._super.apply(this, arguments);
      this.createChart();
      var self = this;
      var parentContainer = this.get('parentContainer');
      if (parentContainer && parentContainer.$()) {
        parentContainer.$().on('resizestop', function (event, element) {
          self.createChart(element.size.height);
          self.set("chartHeight", element.size.height - 55 + "px");
        });
      }
      this.createChart();
    },
    getDefaultOptions: function getDefaultOptions(widgetheight) {
      var yAxisFormat = this.yAxisFormat;
      var legendType = "plain";
      var visual = this.visual;

      // specify chart configuration item and data
      return {
        grid: {
          containLabel: true
        },
        tooltip: {
          trigger: "axis",
          formatter: function formatter(params, ticket, callback) {
            var tooltip = "";
            params.forEach(function (m) {
              tooltip = m.marker + " " + m.seriesName + " " + textformatter.format(m.data, yAxisFormat) + "<br/>" + tooltip;
            });
            return tooltip;
          }
        },
        legend: {
          type: legendType,
          data: []
        },
        toolbox: {
          orient: 'vertical',
          feature: {
            dataZoom: {
              show: true,
              title: {
                zoom: "Zoom",
                back: "Undo Zoom"
              }
            },
            restore: {
              title: "Reset"
            }
          },
          tooltip: {
            confine: true
          },
          right: '15px'
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            interval: 1
          }
        },
        yAxis: {
          axisLabel: {
            show: true
          },
          type: "value"
        },
        series: []
      };
    },
    options: null
  });
});