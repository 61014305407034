define("insights-web/components/call-detail-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $ = Ember.$;
  var _default = _exports.default = Ember.Component.extend({
    requestService: Ember.inject.service(),
    analyticsService: Ember.inject.service(),
    selectedConversation: "8817b816-961e-4fcc-ac2e-eb0d1466e3ba",
    selectedQueues: [],
    queueIds: [],
    interval: "",
    pagedResults: [],
    currentPage: null,
    queryInProgress: false,
    currentPageIndex: 0,
    error: null,
    hasResults: false,
    isQueryVisible: true,
    pageData: [],
    aniValue: "",
    conversationDetail: null,
    init: function init() {
      this._super.apply(this, arguments);
    },
    setSelectedPage: function setSelectedPage(index) {
      var pages = this.get("pagedResults");
      this.set("currentPage", pages[index]);
      this.set("currentPageIndex", index);
    },
    valuesChanged: Ember.observer('selectedPage', function () {
      var page = this.get("selectedPage");
      this.setSelectedPage(page.page);
    }),
    tableColumns: Ember.computed(function () {
      /*
      mediaType: "",
      conversationId: conversation.conversationId,
      conversationStart: conversation.conversationStart,
      remoteName:"",
      ani: "",
      dnis: "",
      direction: "",
      ivrCount: 0,
      ivrTime: 0,
      alertCount: 0,
      totalAlertTime: 0,
      agentHandleCount: 0,
      agentInteractionTime: 0,
      wrapUpCount: 0,
      totalWrapUpTime: 0,
       totalQueueWaitTime: 0,
      queueCount:0,
      firstQueue:"",
      wentToVoicemail: "no",
      voicemailDuration: 0
      */
      var columns = [{
        header: 'Conversation Id',
        key: 'conversationId'
      }, {
        header: 'Media Type',
        key: 'mediaType'
      }, {
        header: 'Conversation Start',
        key: 'conversationStart'
      }, {
        header: 'Remote Name',
        key: 'remoteName'
      }, {
        header: 'ANI',
        key: 'ani'
      }, {
        header: 'DNIS',
        key: 'dnis'
      }, {
        header: 'Direction',
        key: 'direction'
      }, {
        header: 'IVR Count',
        key: 'ivrCount',
        summary: "sum"
      }, {
        header: 'IVR Time',
        key: 'ivrTime',
        summary: "sum"
      }, {
        header: 'Alert Count',
        key: 'alertCount',
        summary: "sum"
      }, {
        header: 'Alert Time',
        key: 'totalAlertTime',
        summary: "sum"
      }, {
        header: 'Agent Handle Count',
        key: 'agentHandleCount',
        summary: "sum"
      }, {
        header: 'Agent Interaction Time',
        key: 'agentInteractionTime',
        summary: "sum"
      }, {
        header: 'Hold Count',
        key: 'holdCount',
        summary: "sum"
      }, {
        header: 'Hold Time',
        key: 'toltalHoldTime',
        summary: "sum"
      }, {
        header: 'Wrap Up Count',
        key: 'wrapUpCount',
        summary: "sum"
      }, {
        header: 'Wrap Up Time',
        key: 'totalWrapUpTime',
        summary: "sum"
      }, {
        header: 'Total Queue Wait Time',
        key: 'totalQueueWaitTime',
        summary: "sum"
      }, {
        header: 'Queue Count',
        key: 'queueCount',
        summary: "sum"
      }, {
        header: 'First Queue',
        key: 'firstQueue'
      }, {
        header: 'Abandoned',
        key: 'abandoned',
        summary: "count",
        count: "yes"
      }, {
        header: 'Abandoned in Queue',
        key: 'abandonQueue'
      }, {
        header: 'Went to Voicemail',
        key: 'wentToVoicemail',
        summary: "count",
        count: "yes"
      }, {
        header: 'Voicemail Duration',
        key: 'voicemailDuration',
        summary: "sum"
      }, {
        header: 'Last Wrapup Code',
        key: 'lastWrapup'
      }];
      return columns;
    }).property(),
    actions: {
      setConversationDetail: function setConversationDetail(conversation) {
        this.set("conversationDetail", null);
        this.set("conversationDetail", conversation.conversationId);
      },
      cancelConversationDetails: function cancelConversationDetails() {
        this.set("conversationDetail", null);
      },
      calculateSummary: function calculateSummary() {
        var results = this.pagedResults;
        var columns = this.get("tableColumns");
        var callCount = 0;
        var summaryData = {};
        for (var x = 0; x < columns.length; x++) {
          var column = columns[x];
          if (column.summary) {
            summaryData[column.key] = {
              header: column.header,
              key: column.key,
              summary: column.summary,
              count: column.count,
              total: 0
            };
          }
        }
        for (var callPageIndex = 0; callPageIndex < results.length; callPageIndex++) {
          var callPage = results[callPageIndex];
          for (var callIndex = 0; callIndex < callPage.length; callIndex++) {
            var call = callPage[callIndex];
            callCount++;
            for (var prop in summaryData) {
              var sumType = summaryData[prop].summary;
              if (sumType === "sum") {
                summaryData[prop].total = summaryData[prop].total + call[prop];
              } else if (sumType === "count") {
                if (call[prop] === summaryData[prop].count) {
                  summaryData[prop].total = summaryData[prop].total + 1;
                }
              }
            }
          }
        }
        summaryData["count"] = {
          total: callCount,
          header: "Interaction Count"
        };
        var lst = [];
        for (var _prop in summaryData) {
          lst.push(summaryData[_prop]);
        }
        this.set("summary", lst);
        this.set("summaryMap", summaryData);
      },
      generateCsv: function generateCsv() {
        var cols = this.get("tableColumns");
        var pages = this.get("pagedResults");
        var rows = [];
        var data = "";
        var headerRow = [];
        for (var _p = 0; _p < cols.length; _p++) {
          headerRow.push(cols[_p].header);
        }
        rows.push(headerRow.join(","));
        if (this.get("csvWithSummary") === true) {
          var summary = this.get("summaryMap");
          var summaryRow = [];
          summaryRow.push("Total");
          for (var p = 1; p < cols.length; p++) {
            if (cols[p].summary) {
              summaryRow.push(summary[cols[p].key].total);
            } else {
              summaryRow.push("");
            }
          }
          rows.push(summaryRow.join(","));
        }
        for (var x = 0; x < pages.length; x++) {
          for (var _p2 = 0; _p2 < pages[x].length; _p2++) {
            var detailRow = [];
            var details = pages[x][_p2];
            for (var _p3 = 0; _p3 < cols.length; _p3++) {
              detailRow.push(details[cols[_p3].key]);
            }
            rows.push(detailRow.join(","));
          }
        }
        data = rows.join("\n");
        if (this.get("csvUrl") !== null) {
          window.URL.revokeObjectURL(this.get("csvUrl"));
        }

        //var json = JSON.stringify(data),
        var blob = new Blob([data], {
          type: 'text/csv'
        });
        var url = window.URL.createObjectURL(blob);
        this.set("hasCSVToDownload", true);
        var a = document.getElementById("downloadCSV");
        a.href = url;
        a.download = "ConversationDetail_" + moment().format("YYYY-MM-DD") + ".csv";
        //  a.click();
        this.set("csvUrl", url);
        $('#downloadContainer').show();
      },
      setPage: function setPage(page) {
        var pages = this.get("pagedResults");
        this.set("currentPage", pages[page]);
        this.set("currentPageIndex", page);
      },
      selectConversation: function selectConversation(conversationId) {
        this.set("selectedConversation", conversationId);
      },
      closeConversation: function closeConversation() {
        this.set("selectedConversation", null);
      },
      toggleQueryPane: function toggleQueryPane() {
        this.set("isQueryVisible", !this.get("isQueryVisible"));
      },
      executeQuery: function executeQuery() {
        this.get("analyticsService").logCallDetailReport();
        var pageNumber = 1;
        var queues = this.get("queueIds");
        var dnis = this.get("dnisValue");
        var ani = this.get("aniValue");

        //18215258401

        if (ani) {
          ani = ani.split(';,');
        }
        if (dnis) {
          dnis = dnis.split(';,');
        }
        var interval = this.get("interval");
        var queueIds = [];
        for (var x = 0; x < queues.length; x++) {
          queueIds.push(queues[x]);
        }
        var query = {
          query: {
            "interval": interval
          },
          meta: {
            queues: queueIds,
            dnis: dnis,
            ani: ani,
            pageSize: 100,
            pageNumber: pageNumber
          }
        };
        Ember.Logger.log(query);
        var self = this;
        this.set("hasResults", false);
        this.set("queryInProgress", true);
        this.set("currentPageIndex", 0);
        this.set("currentPage", []);
        this.set("hasCSVToDownload", false);
        this.pagedResults.clear();
        this.pageData.clear();
        function getPage(number) {
          query.meta.pageNumber = number;
          self.get("requestService").postResource('/execute/v1/calldetail', query).then(function (results) {
            if (results && results.length > 0) {
              self.pagedResults.addObject(results);
              //    self.tableContentX.addObjects(results);
              getPage(number + 1);
              if (number === 0) {
                self.set("currentPageIndex", 0);
                self.set("currentPage", results);
              }
              var startTime = moment(results[0].conversationStart).format('ddd, MMMM Do YYYY, h:mm:ss a');
              var endTime = moment(results[results.length - 1].conversationStart).format('ddd, MMMM Do YYYY, h:mm:ss a');
              self.pageData.pushObject({
                range: "".concat(startTime, " - ").concat(endTime),
                page: number - 1
              });
            } else {
              self.set("queryInProgress", false);
              self.set("hasResults", true);
              self.send("calculateSummary");
              self.set("isQueryVisible", false);
              self.setSelectedPage(0);
            }
          }).catch(function (err) {
            self.set("queryInProgress", false);
            var errormessage = JSON.stringify(err, null, " ");
            if (err.error) {
              errormessage = err.error;
            }
            self.set("error", errormessage);
            Ember.Logger.error(err);
            self.setSelectedPage(0);
          });
        }
        this.set("queryInProgress", true);
        getPage(1);
      }
    }
  });
});