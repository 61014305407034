define("insights-web/services/observation-stat-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SnapshotIntervalSeconds = 20;
  var SnapshotInterval = SnapshotIntervalSeconds * 1000;
  var LocalStorageObservationKey = 'observationGraphData';
  var StatKeys = ['oUserRoutingStatuses.INTERACTING', 'oUserRoutingStatuses.IDLE', 'oUserRoutingStatuses.NOT_RESPONDING', 'oWaiting', 'oInteracting'];
  var _default = _exports.default = Ember.Service.extend({
    stats: {},
    currentValues: {},
    statCalculation: Ember.inject.service(),
    realtimeService: Ember.inject.service(),
    requestService: Ember.inject.service(),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.get("updateFlag", true);
      this.loadDashboardConfig();

      // if(localStorage.getItem(LocalStorageObservationKey)){
      //     try{
      //         let data = JSON.parse(localStorage.getItem(LocalStorageObservationKey));
      //         if(data){
      //          this.set("stats", data);
      //         }
      //     }
      //     catch(ex){ }
      // }

      // later(this, function() {
      //     this.snapShotWrapper();
      // }, 3000);  

      $(window).on('beforeunload', function () {
        localStorage.setItem(LocalStorageObservationKey, JSON.stringify(_this.get("stats")));
      });
    },
    loadDashboardConfig: function loadDashboardConfig() {
      var _this2 = this;
      var snapshotLater = this.get("snapShotLater");
      if (snapshotLater) {
        Ember.run.cancel(snapshotLater);
        this.set("snapShotLater", null);
      }
      var queueIds = {};
      var queueIdList = [];
      var requestService = this.get("requestService");
      this.get('realtimeService').get("dashboardConfiguration").dashboards.forEach(function (dashboard) {
        dashboard.widgets.forEach(function (widget) {
          if (widget.widgetType === 'observationchart') {
            var queueId = widget.agentStatusQueue;
            queueIdList.pushObject(queueId);
            if (!widget.duration || parseInt(widget.duration) <= 0) {
              queueIds[queueId] = 1;
            } else if (typeof queueIds[queueId] === "undefined" || parseInt(widget.duration) > queueIds[queueId]) {
              queueIds[queueId] = parseInt(widget.duration);
            }
            if (widget.queueIds) {
              widget.queueIds.forEach(function (queueId) {
                if (queueIdList.indexOf(queueId) === -1) {
                  queueIdList.pushObject(queueId);
                }
                if (!widget.duration || parseInt(widget.duration) <= 0) {
                  queueIds[queueId] = 1;
                } else if (typeof queueIds[queueId] === "undefined" || parseInt(widget.duration) > queueIds[queueId]) {
                  queueIds[queueId] = parseInt(widget.duration);
                }
              });
            }
          }
        });
      });
      var statPromises = [];
      while (queueIdList.length > 0) {
        var subset = queueIdList.splice(0, 10);
        statPromises.pushObject(requestService.postResource('/dashboards/observation/stats', subset));
      }
      Promise.all(statPromises).then(function (promiseResults) {
        var stats = {};
        promiseResults.forEach(function (promiseStats) {
          Object.keys(promiseStats).forEach(function (queueId) {
            stats[queueId] = promiseStats[queueId];
          });
        });
        Object.keys(stats).forEach(function (queueId) {
          Object.keys(stats[queueId]).forEach(function (metric) {
            stats[queueId][metric].forEach(function (val) {
              val.t = moment(val.t).format();
            });
          });
        });
        _this2.set("stats", stats);
        _this2.set("updateFlag", !_this2.get("updateFlag"));
        if (!_this2.get("snapShotLater")) {
          _this2.snapShotWrapper();
        }
      }).catch(function (err) {
        console.error("Unable to get observation stats history");
        console.error(err);
      });
      this.set("queueIds", queueIds);
    },
    dashboardConfigurations: Ember.observer('realtimeService.dashboardConfigurationChangedToggle', function () {
      this.loadDashboardConfig();

      // later(this, function() {
      //     this.snapShot();
      // }, 3000);        
    }),
    hoursToKeep: function hoursToKeep() {
      return 1;
    },
    getDataTable: function getDataTable(agentStatusQueue, conversationMetricQueues, metrics, previousHours) {
      var stats = this.get('stats');
      if (!previousHours || previousHours < 1) {
        previousHours = this.hoursToKeep();
      }
      var result = [];

      // let header = ["Time"]
      // metrics.forEach((m) => {
      //     header.pushObject(m);
      // });

      // result.pushObject(header);

      var resultRowMap = {};
      var refQueue = stats[agentStatusQueue];
      if (!refQueue) {
        var defaultRow = [""];
        metrics.forEach(function (m) {
          defaultRow.pushObject(0);
        });
        result.pushObject(defaultRow);
        return result;
      }
      refQueue["oWaiting"].forEach(function (m) {
        var row = [];
        metrics.forEach(function (m) {
          row.pushObject(0);
        });
        resultRowMap[m.t] = row;
      });
      var defaultArr = [];
      for (var x = 0; x < metrics.length; x++) {
        defaultArr.pushObject(0);
      }
      var _loop = function _loop(_x) {
        var metric = metrics[_x];
        if (metric.indexOf("oUserRoutingStatuses") > -1) {
          var queueId = agentStatusQueue;
          if (stats[queueId]) {
            var queueStats = stats[queueId];
            if (queueStats[metric]) {
              var value = queueStats[metric].slice(0);

              //Clear out old values
              while (value[0] && moment(value[0].t) < moment().subtract(previousHours, 'h')) {
                value.shift();
              }
              value.forEach(function (v) {
                if (resultRowMap[v.t]) {
                  resultRowMap[v.t][_x] = v.y;
                }
              });
            }
          }
        } else {
          for (var y = 0; y < conversationMetricQueues.length; y++) {
            var _queueId = conversationMetricQueues[y];
            if (stats[_queueId]) {
              var _queueStats = stats[_queueId];
              if (_queueStats[metric]) {
                var values = _queueStats[metric].slice(0);
                for (var i = 0; i < values.length; i++) {
                  var _value = values[i];
                  if (moment(_value.t) > moment().subtract(previousHours, 'h')) {
                    if (!resultRowMap[_value.t]) {
                      resultRowMap[_value.t] = defaultArr;
                    }
                    resultRowMap[_value.t][_x] = resultRowMap[_value.t][_x] + _value.y;
                  }
                }
              }
            }
          }
        }
      };
      for (var _x = 0; _x < metrics.length; _x++) {
        _loop(_x);
      }
      refQueue["oWaiting"].forEach(function (m) {
        var row = [moment(m.t).format("h:mm")];
        // let row = [moment(m.t).toDate()];
        // let row = [(m.t)];

        resultRowMap[m.t].forEach(function (y) {
          if (y == 0) {
            row.pushObject(y);
          } else {
            row.pushObject(y);
          }
        });
        result.pushObject(row);
      });
      return result;
    },
    getValues: function getValues(agentStatusQueue, conversationMetricQueues, metric, previousHours) {
      var stats = this.get('stats');
      if (!previousHours || previousHours < 1) {
        previousHours = this.hoursToKeep();
      }

      // debugger;
      if (metric.indexOf("oUserRoutingStatuses") > -1) {
        var queueId = agentStatusQueue;
        if (stats[queueId]) {
          var queueStats = stats[queueId];
          if (queueStats[metric]) {
            var value = queueStats[metric].slice(0);

            //Clear out old values
            while (value[0] && moment(value[0].t) < moment().subtract(previousHours, 'h')) {
              value.shift();
            }
            return value;
          }
        }
      } else {
        var valueMap = {};
        for (var x = 0; x < conversationMetricQueues.length; x++) {
          var _queueId2 = conversationMetricQueues[x];
          if (stats[_queueId2]) {
            var _queueStats2 = stats[_queueId2];
            if (_queueStats2[metric]) {
              var values = _queueStats2[metric].slice(0);
              for (var _x2 = 0; _x2 < values.length; _x2++) {
                var _value2 = values[_x2];
                if (moment(_value2.t) > moment().subtract(previousHours, 'h')) {
                  if (Object.keys(valueMap).indexOf(_value2.t) === -1) {
                    valueMap[_value2.t] = 0;
                  }
                  valueMap[_value2.t] = valueMap[_value2.t] + _value2.y;
                }
              }
            }
          }
        }
        var valueList = [];
        Object.keys(valueMap).sort().forEach(function (key) {
          valueList.pushObject({
            t: key,
            y: valueMap[key]
          });
        });
        return valueList;
      }
      return [];
    },
    snapShotWrapper: function snapShotWrapper() {
      if (this && !this.isDestroyed) {
        var snapshotLater = Ember.run.later(this, function () {
          this.snapShotWrapper();
        }, SnapshotInterval);
        this.set("snapShotLater", snapshotLater);
      }
      this.snapShot();
    },
    snapShot: function snapShot() {
      var _this3 = this;
      if (!this.get("queueIds")) {
        return;
      }
      var stats = this.get('stats');
      var now = moment().format();
      Object.keys(this.get("queueIds")).forEach(function (queueId) {
        if (!stats[queueId]) {
          stats[queueId] = {};
        }
        StatKeys.forEach(function (metricKey) {
          if (!stats[queueId][metricKey]) {
            stats[queueId][metricKey] = [];
          }
          var value = _this3.get("statCalculation").getStatValue([queueId], metricKey).data;

          //Fill in zeros
          if (stats[queueId][metricKey].length > 3) {
            while (moment(stats[queueId][metricKey][stats[queueId][metricKey].length - 1].t) < moment().subtract(SnapshotIntervalSeconds * 3, 's')) {
              stats[queueId][metricKey].push({
                t: moment(stats[queueId][metricKey][stats[queueId][metricKey].length - 1].t).add(SnapshotIntervalSeconds + 1, 's').format(),
                y: 0
              });
            }
          }
          stats[queueId][metricKey].push({
            t: now,
            y: value
          });
          var maxDuration = _this3.get("queueIds")[queueId] || 1;
          while (moment(stats[queueId][metricKey][0].t) < moment().subtract(maxDuration, 'h')) {
            stats[queueId][metricKey].shift();
          }
        });
      });
      this.set("stats", stats);
      this.set("updateFlag", !this.get("updateFlag"));
    }
  });
});