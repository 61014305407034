define("insights-web/components/datetime-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var self = this;
      function updateTime() {
        Ember.run.later(self, function () {
          updateTime();
        }, 1000);
        if (self && !self.isDestroyed) {
          self.set("currentTime", moment().format("dddd, MMMM Do YYYY"));
        }
      }
      Ember.run.later(this, function () {
        updateTime();
      }, 1000);
    }
  });
});