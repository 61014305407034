define("insights-web/templates/components/web-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "61Bf3Zhn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"iframe\"],[15,\"src\",[34,0,[\"title\"]]],[14,\"width\",\"100%\"],[15,\"height\",[31,[[34,1],\"px\"]]],[14,\"frameborder\",\"0\"],[14,\"scrolling\",\"no\"],[14,\"sandbox\",\"allow-same-origin allow-scripts\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"configuration\",\"iframeHeight\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/web-frame.hbs"
    }
  });
});