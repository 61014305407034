define("insights-web/services/storage-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    localStorageSet: function localStorageSet(key, value) {
      try {
        if (typeof window.localStorage !== 'undefined') {
          window.localStorage[key] = JSON.stringify(value);
        }
      } catch (ex) {
        Ember.Logger.warn(ex);
      }
    },
    localStorageGet: function localStorageGet(key) {
      try {
        if (typeof window.localStorage !== 'undefined') {
          return JSON.parse(window.localStorage[key]);
        }
      } catch (ex) {
        // empty
      }
      return null;
    },
    sessionStorageSet: function sessionStorageSet(key, value) {
      try {
        if (typeof window.sessionStorage !== 'undefined') {
          window.sessionStorage[key] = JSON.stringify(value);
        }
      } catch (ex) {
        Ember.Logger.warn(ex);
      }
    },
    sessionStorageGet: function sessionStorageGet(key) {
      try {
        if (typeof window.sessionStorage !== 'undefined') {
          return JSON.parse(window.sessionStorage[key]);
        }
      } catch (ex) {
        // empty
      }
      return null;
    }
  });
});