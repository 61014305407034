define("insights-web/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8VEdvEKv",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,9],[[35,8],true],null]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"div\"],[14,0,\"loading-background\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"loading-content\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-spinner fa-pulse fa-2x fa-fw\"],[14,5,\"float:left\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"message\"],[12],[2,\"\\n      Loading\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[10,\"div\"],[15,0,[31,[\"app \",[30,[36,3],[[35,2],\"embedded\",\"notembedded\"],null]]]],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"position\"],[\"top-right\"]]]],[2,\"\\n\"],[6,[37,5],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,1,\"header\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"organizationId\"],[[35,0]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"main-content\"],[12],[2,\"\\n    \"],[1,[30,[36,7],[[30,[36,6],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"powered-by\"],[12],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"text-muted\"],[12],[2,\"Powered by\"],[13],[10,\"img\"],[14,\"src\",\"/images/pureinsights_alpha.png\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"organizationId\",\"nav-bar\",\"isEmbedded\",\"if\",\"notification-container\",\"unless\",\"-outlet\",\"component\",\"isLoading\",\"eq\"]}",
    "meta": {
      "moduleName": "insights-web/templates/application.hbs"
    }
  });
});