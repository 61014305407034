define("insights-web/services/purecloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COOKIE_NAME = "purecloudTokenV2";
  var _default = _exports.default = Ember.Service.extend({
    cookies: Ember.inject.service(),
    _session: null,
    getRegion: function getRegion() {
      var cookieService = this.get('cookies');
      var cookie = JSON.parse(cookieService.read(COOKIE_NAME));
      var region = cookie.region;
      if (!region) {
        region = "mypurecloud.com";
      }
      return region;
    },
    getAccessToken: function getAccessToken() {
      var cookieService = this.get('cookies');
      var cookie = JSON.parse(cookieService.read(COOKIE_NAME));
      return cookie.access_token;
    },
    getUserId: function getUserId() {
      var cookieService = this.get('cookies');
      var cookie = JSON.parse(cookieService.read(COOKIE_NAME));
      return cookie.userId;
    },
    getSessionId: function getSessionId() {
      var cookieService = this.get('cookies');
      var cookie = JSON.parse(cookieService.read(COOKIE_NAME));
      return cookie.getSessionId;
    },
    _getSession: function _getSession() {
      var session = this.get("_session");
      if (session === null) {
        var region = this.getRegion();
        session = purecloud.platform.PureCloudSession({
          strategy: 'token',
          token: this.getAccessToken(),
          timeout: 45000,
          environment: region
        });
        session.setUnauthenticatedErrorHandler(function () {
          window.location.reload();
        });
        this.set("_session", session);
      }
      return session;
    },
    post: function post(url, data) {
      return this._getSession().makeRequest("POST", url, "", data);
    },
    getRequest: function getRequest(url) {
      return this._getSession().makeRequest("GET", url, "");
    },
    getAnalyticsApi: function getAnalyticsApi() {
      var session = this._getSession();
      var api = new purecloud.platform.AnalyticsApi(session);
      return api;
    },
    getSearchApi: function getSearchApi() {
      var session = this._getSession();
      var api = new purecloud.platform.SearchApi(session);
      return api;
    },
    getNotificationsApi: function getNotificationsApi() {
      var session = this._getSession();
      var api = new purecloud.platform.NotificationsApi(session);
      return api;
    },
    getOutboundApi: function getOutboundApi() {
      var session = this._getSession();
      var api = new purecloud.platform.OutboundApi(session);
      return api;
    },
    getRoutingApi: function getRoutingApi() {
      var session = this._getSession();
      var api = new purecloud.platform.RoutingApi(session);
      return api;
    },
    getPresenceApi: function getPresenceApi() {
      var session = this._getSession();
      var api = new purecloud.platform.PresenceApi(session);
      return api;
    }
  });
});