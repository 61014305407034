define("insights-web/utils/stattimeperiods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var stattimeperiods = [{
    "id": "day",
    "name": "Current Day"
  }, {
    "id": "currentinterval",
    "name": "Current Interval"
  }, {
    "id": "previousinterval",
    "name": "Previous Interval"
  }];
  var _default = _exports.default = stattimeperiods;
});