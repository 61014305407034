define("insights-web/templates/components/configuration/webframe-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TRAd3X+K",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-md-1\"],[12],[2,\"\\n        \"],[10,\"label\"],[12],[2,\"Url\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-md-10\"],[12],[2,\"\\n        \"],[1,[30,[36,1],null,[[\"class\",\"value\"],[\"form-control\",[35,0,[\"title\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"editWidget\",\"input\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/configuration/webframe-widget.hbs"
    }
  });
});