define("insights-web/templates/components/queue-interaction-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9TnJbS2g",
    "block": "{\"symbols\":[\"r\",\"rowindex\"],\"statements\":[[10,\"span\"],[14,0,\"h3\"],[15,5,[31,[\"font-size:\",[34,0]]]],[12],[2,\"\\n    \"],[1,[35,1,[\"title\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"scroll-table-container\"],[12],[2,\"\\n\"],[10,\"table\"],[14,0,\"table table-striped table-condensed stat-table\"],[15,5,[31,[\"font-size:\",[34,1,[\"fontsize\"]],\"px\"]]],[12],[2,\"\\n\"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"\\n        Remote Name\\n    \"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"\\n        Wait Time\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"tr\"],[12],[2,\"\\n    \\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[32,1,[\"participantName\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[32,1,[\"timeWaiting\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \\n\"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"titlesize\",\"configuration\",\"calls\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/queue-interaction-list.hbs"
    }
  });
});