define("insights-web/services/request-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  //const SERVER = "http://d22huhlq55w9kn.cloudfront.net";
  var SERVER = ""; // "http://localhost:8080";

  function makeRequestWithRetry(request, resolve, reject) {
    $.ajax(request).done(function (queues) {
      resolve(queues);
    }).catch(function (err) {
      if (err.status === 401) {
        self._loginRedirect(self);
        resolve();
      } else if (err.status === 401) {
        self._loginRedirect(self);
        reject(err);
      } else if (err.status === 429) {
        setTimeout(function () {
          makeRequestWithRetry(request, resolve, reject);
        }, 30000);
      } else {
        Ember.Logger.error(err);
        reject(err);
      }
    });
  }
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    ajax: Ember.inject.service(),
    errorService: Ember.inject.service(),
    getServer: function getServer() {
      return SERVER + "/server";
    },
    me: null,
    getMe: function getMe() {
      if (this.get("me") !== null) {
        return Ember.RSVP.Promise.resolve(this.get("me"));
      }
      var self = this;
      return this.getResource("/purecloud/me").then(function (me) {
        self.set("me", me);
        return me;
      });
    },
    _loginRedirect: function _loginRedirect() {
      window.location.replace(SERVER + "/server/oauth/login?redirect=" + window.location.href);
    },
    logError: function logError(message, correlationId) {
      this.get("errorService").logError(message, correlationId);
    },
    getResource: function getResource(url) {
      var self = this;
      try {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          //resolve({});
          self.get('ajax').request(self.getServer(url) + url /*{
                                                             url: self.getServer(url) + url,
                                                             //  data: { whatever:'cool' },
                                                             type: 'GET',
                                                             beforeSend: function(xhr){
                                                             xhr.withCredentials = true;
                                                             },
                                                             }*/).then(function (data) {
            resolve(data);
          }).catch(function (err) {
            try {
              Ember.Logger.error(err);
              Ember.Logger.error(_typeof(err));
              if (err.status === 401 || err.message === "Ajax authorization failed") {
                Ember.Logger.log("REDIRECTING");
                self._loginRedirect(self);
              }
              if (reject) {
                reject(err);
              }
            } catch (e) {
              Ember.Logger.error("error caught in get resource " + url + " " + e);
              if (reject) {
                reject(e);
              }
            }
          });
        });
      } catch (e) {
        Ember.Logger.error(e);
      }
    },
    postResource: function postResource(url, data) {
      var self = this;
      var token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      try {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var request = {
            url: self.getServer(url) + url,
            type: 'POST',
            contentType: 'application/json',
            //json object to sent to the authentication url
            data: JSON.stringify(data),
            headers: {
              'CSRF-Token': token
            },
            beforeSend: function beforeSend(xhr) {
              xhr.withCredentials = true;
            }
          };
          makeRequestWithRetry(request, resolve, reject);
        });
      } catch (e) {
        Ember.Logger.error(e);
      }
    }
  });
});