define("insights-web/templates/components/configuration/clock-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Wo8yjauJ",
    "block": "{\"symbols\":[],\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-2\"],[12],[2,\"\\n            \"],[10,\"label\"],[12],[2,\"Time Zone\"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n            \"],[1,[30,[36,3],null,[[\"items\",\"selectedItem\"],[[35,2],[35,0,[\"title\"]]]]]],[2,\"\\n        \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-2\"],[12],[2,\"\\n\\n            \"],[1,[30,[36,1],null,[[\"type\",\"checked\"],[\"checkbox\",[35,0,[\"hourformat24\"]]]]]],[2,\"\\n            \"],[10,\"label\"],[12],[2,\"24 Hour format\"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-2\"],[12],[2,\"\\n            \\n\\n            \"],[1,[30,[36,1],null,[[\"type\",\"checked\"],[\"checkbox\",[35,0,[\"useCustomLabel\"]]]]]],[2,\"\\n            \"],[10,\"label\"],[12],[2,\"Custom Label\"],[13],[2,\"\\n           \\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-4\"],[12],[2,\"\\n\"],[6,[37,4],[[35,0,[\"useCustomLabel\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,1],null,[[\"class\",\"value\"],[\"form-control\",[35,0,[\"customLabel\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13]],\"hasEval\":false,\"upvars\":[\"editWidget\",\"input\",\"timeZones\",\"select-box\",\"if\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/configuration/clock-widget.hbs"
    }
  });
});