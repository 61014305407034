define("insights-web/components/stat-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    expander: [],
    actions: {
      toggleExpand: function toggleExpand(index) {
        this.onToggleExpand(index);
      }
    }
  });
});