define("insights-web/components/select-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      selectItem: function selectItem(item) {
        this.set("selectedItem", item);
      }
    }
  });
});