define("insights-web/templates/components/observation-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mofF5iY+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"b\"],[14,0,\"chart-title\"],[14,5,\"min-height:30px;display:inline-block;\"],[12],[1,[35,0,[\"title\"]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"chart\"],[15,5,[31,[\"width: 100%;height:\",[34,1],\";\"]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"configuration\",\"chartHeight\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/observation-graph.hbs"
    }
  });
});