define("insights-web/templates/components/agent-stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QEmApd6H",
    "block": "{\"symbols\":[\"r\",\"rowindex\",\"c\",\"index\",\"value\",\"index\"],\"statements\":[[10,\"div\"],[14,0,\"scroll-table-container\"],[12],[2,\"\\n\"],[10,\"table\"],[14,0,\"table table-striped table-condensed stat-table\"],[15,5,[31,[\"font-size:\",[34,3,[\"fontsize\"]],\"px\"]]],[12],[2,\"\\n\"],[10,\"tr\"],[12],[2,\"\\n    \\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"th\"],[12],[2,\"\\n\"],[1,[32,5]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[5,6]}]]],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1,[\"stats\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"td\"],[15,0,[32,3,[\"cssClass\"]]],[15,5,[31,[\"border-left-color:\",[32,3,[\"customColor\"]]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,3,[\"image\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"img\"],[14,\"height\",\"40px\"],[14,\"width\",\"40px\"],[14,5,\"margin-top:2px;margin-bottom:2px; border-radius:50%\"],[15,\"src\",[32,3,[\"image\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[1,[32,3,[\"value\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[3,4]}]]],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"],[1,[34,6]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\",\"configuration\",\"headers\",\"data\",\"errorMessage\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/agent-stats.hbs"
    }
  });
});