define("insights-web/services/campaign-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    requestService: Ember.inject.service(),
    campaignMap: {},
    campaignIdToNameMap: {},
    campaignList: [],
    updateCampaignFromPureCloud: function updateCampaignFromPureCloud() {
      var self = this;
      this.get("requestService").getResource("/purecloud/campaigns").then(function (campaigns) {
        var map = {};
        var campaignIdToNameMap = {};
        for (var x = 0; x < campaigns.length; x++) {
          map[campaigns[x].name] = campaigns[x].id;
          campaignIdToNameMap[campaigns[x].id] = campaigns[x].name;
        }
        self.set("campaignIdToNameMap", campaignIdToNameMap);
        self.set("queueMap", map);
        self.set("campaignList", campaigns);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.updateCampaignFromPureCloud();
    }
  });
});