define("insights-web/components/trial-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var COOKIE_NAME = "purecloudTokenV2";
  var _default = _exports.default = Ember.Component.extend({
    cookies: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      try {
        var cookieService = this.get('cookies');
        var cookie = JSON.parse(cookieService.read(COOKIE_NAME));
        if (cookie.trialExpires) {
          var expires = moment(cookie.trialExpires);
          this.set("daysRemaining", expires.diff(moment(), 'days'));
          this.set("isTrial", true);
        } else {
          this.set("isTrial", false);
        }
      } catch (ex) {}
    }
  });
});