define("insights-web/components/select-box-advanced", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      Ember.run.later(this, function () {
        var selectedItem = this.get("selectedItem");
        var items = this.get("items");
        if (!selectedItem && items) {
          if (items[0].id) {
            this.set("selectedItem", items[0].id);
          } else {
            this.set("selectedItem", items[0]);
          }
        }
      }, 500);
    },
    actions: {
      selectItem: function selectItem(item) {
        this.set("selectedItem", item);
      }
    }
  });
});