define("insights-web/templates/components/chart-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0NssGP5l",
    "block": "{\"symbols\":[],\"statements\":[[10,\"b\"],[14,0,\"chart-title\"],[12],[1,[35,0,[\"title\"]]],[2,\" \"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"(in seconds)\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[1,[30,[36,5],null,[[\"type\",\"data\",\"options\"],[[35,0,[\"chartType\"]],[35,4],[35,3]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"configuration\",\"isTimeData\",\"if\",\"options\",\"chartData\",\"ember-chart\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/chart-widget.hbs"
    }
  });
});