define("insights-web/controllers/mypermissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    requestService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var self = this;
      this.get("requestService").getResource('/purecloud/me').then(function (me) {
        var permissions = me.authorization.permissions;
        self.set("permissions", permissions.sort());
      });
      this.get("requestService").getResource("/purecloud/queues").then(function (queues) {
        self.set("queues", queues);
      });
    }
  });
});