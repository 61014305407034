define("insights-web/utils/observationGraphStatTypes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var observationGraphStatTypes = [{
    "key": "observation_conversations_waiting",
    "text": "Conversations Waiting",
    "legendText": "Waiting",
    "observationMetric": "oWaiting",
    "metrics": ["oWaiting"]
  }, {
    "key": "observation_agents_not_responding",
    "text": "Not Responding Agents",
    "legendText": "Not Responding",
    "observationMetric": "oUserRoutingStatuses.NOT_RESPONDING",
    "metrics": ["oOnQueueUsers", "oUserRoutingStatuses"]
  }, {
    "key": "observation_agents_idle",
    "text": "Idle Agents",
    "legendText": "Idle",
    "observationMetric": "oUserRoutingStatuses.IDLE",
    "metrics": ["oOnQueueUsers", "oUserRoutingStatuses"]
  }, {
    "key": "observation_agents_interacting",
    "text": "Conversations Interacting",
    "legendText": "Interacting",
    // "observationMetric": "oUserRoutingStatuses.INTERACTING",
    // "metrics": ["oOnQueueUsers", "oUserRoutingStatuses"],
    "observationMetric": "oInteracting",
    "metrics": ["oInteracting"]
  }];
  var _default = _exports.default = observationGraphStatTypes;
});