define("insights-web/components/two-col-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    selectedAvailableItems: [],
    selectedSelectedItems: [],
    disableMoveSelected: true,
    actions: {
      selectAvailable: function selectAvailable(Items) {
        this.set("selectedAvailableItems", Ember.$(Items.target).val());
      },
      selectSelected: function selectSelected(Items) {
        this.set("selectedSelectedItems", Ember.$(Items.target).val());
        this.set("disableMoveSelected", this.selectedSelectedItems.length !== 1);
      },
      selectItems: function selectItems() {
        var selectedAvailable = this.get("selectedAvailableItems");
        //let availableItems = this.get("availableItems");

        this.selectedItems.addObjects(selectedAvailable);
        for (var x = 0; x < selectedAvailable.length; x++) {
          this.availableItems.removeObject(selectedAvailable[x]);
        }
      },
      selectedItemUp: function selectedItemUp() {
        var selectedSelectedItems = this.get("selectedSelectedItems");
        var selectedItem = selectedSelectedItems[0];
        var selectedIndex = this.selectedItems.indexOf(selectedItem);
        if (selectedIndex === 0) {
          return;
        }
        this.selectedItems.removeAt(selectedIndex, 1);
        this.selectedItems.insertAt(selectedIndex - 1, selectedItem);
      },
      selectedItemDown: function selectedItemDown() {
        var selectedSelectedItems = this.get("selectedSelectedItems");
        var selectedItem = selectedSelectedItems[0];
        var selectedIndex = this.selectedItems.indexOf(selectedItem);
        if (selectedIndex >= this.selectedItems.length) {
          return;
        }
        this.selectedItems.insertAt(selectedIndex + 2, selectedItem);
        this.selectedItems.removeAt(selectedIndex, 1);
      },
      removeSelectedItems: function removeSelectedItems() {
        var selectedSelectedItems = this.get("selectedSelectedItems");
        //let selectedItems = this.get("selectedItems");

        this.availableItems.addObjects(selectedSelectedItems);
        for (var x = 0; x < selectedSelectedItems.length; x++) {
          this.selectedItems.removeObject(selectedSelectedItems[x]);
        }

        //  this.set("availableItems",availableItems);
        //this.set("selectedItems",selectedItems);
      }
    }
  });
});