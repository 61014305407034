define("insights-web/services/widget-state-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    _data: {},
    getState: function getState(dashboardIndex, widgetIndex) {
      var data = this.get("_data");
      var key = "d".concat(dashboardIndex, "w").concat(widgetIndex);
      if (typeof data[key] !== "undefined") {
        return data[key];
      }
      return null;
    },
    setState: function setState(dashboardIndex, widgetIndex, data) {
      var key = "d".concat(dashboardIndex, "w").concat(widgetIndex);
      Ember.set(this, "_data.".concat(key), data);
    },
    clear: function clear() {
      this.set("_data", {});
    }
  });
});