define("insights-web/services/missing-permission-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    notifications: Ember.inject.service('notification-messages'),
    hasCampaignView: false,
    permissions: [],
    reportMissing: function reportMissing(permission, err) {
      //messageParams is returned from the api, but does not make it here as part of the error
      if (err.messageParams && err.divisions) {
        permission = permission + " for division " + err.divisions;
      } else if (err.message && err.message.indexOf("provided division")) {
        permission = permission + " for unknown division";
      }
      if (this.permissions.indexOf(permission) === -1) {
        this.permissions.pushObject(permission);
        this.get("notifications").error("Missing permission ".concat(permission));
      }
    }
  });
});