define("insights-web/templates/components/static-text-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6GHEqpIW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"static-text-widget widget-content\"],[15,5,[31,[\"overflow:hidden;\\nz-index:1;\\n  position:absolute; font-size:\",[34,0,[\"fontsize\"]],\"pt;color:\",[34,0,[\"fontcolor\"]]]]],[12],[2,\"\\n    \"],[1,[30,[36,1],[[35,0,[\"title\"]]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"configuration\",\"html-string\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/static-text-widget.hbs"
    }
  });
});