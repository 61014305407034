define("insights-web/helpers/statgridcolclass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.statGridColClass = statGridColClass;
  function statGridColClass(params /*, hash*/) {
    var index = params[0];
    if (index === 0) {
      return "col-sm-3 col-md-2 col-lg-2";
    }
    if (index <= 3) {
      return "col-sm-3 col-md-2 hidden-xs  col-lg-1";
    }
    if (index < 6) {
      return " col-lg-1 col-md-2 hidden-xs hidden-sm";
    }
    return "col-lg-1 hidden-xs hidden-md hidden-sm";
  }
  var _default = _exports.default = Ember.Helper.helper(statGridColClass);
});