define("insights-web/components/chart-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SECONDS_FACTOR = 1000; // / 60;
  var _default = _exports.default = Ember.Component.extend({
    queueService: Ember.inject.service(),
    colors: ['#1e7145', "#da532c", "#9f00a7", '#00aba9', " #ff0097", '#2d89ef', "#99b433", "#b91d47", "#38200D", "#084081", "#80CDC1", "#bf812d", "#543005", "#C7EAE5", "#0868AC", "#4EB3D3", "#DFC27D", "#8C510A", "#38200D"],
    realtimeService: Ember.inject.service(),
    _isTimeData: function _isTimeData() {
      var configuration = this.get("configuration");
      var isTime = configuration.type === "tAnswered" || configuration.type === "tAbandon" || configuration.type === "tAcd" || configuration.type === "tHeldComplete";
      this.set("isTimeData", isTime);
      return isTime;
    },
    queuesChanged: Ember.observer('queueService.queueList', function () {
      this.createChart();
    }),
    darkModeChanged: Ember.observer('darkMode', function () {
      var labelColor = "#666";
      if (this.get("darkMode") === true) {
        labelColor = "white";
      }
      Chart.defaults.global.defaultFontColor = labelColor;
    }),
    getAggregateDataWithTimezoneOffset: function getAggregateDataWithTimezoneOffset(stat, start, end, queues, granularity) {
      var realtimeService = this.get("realtimeService");
      return new Ember.RSVP.Promise(function (resolve, reject) {
        realtimeService.getAggregateData(stat, start, end, queues, "PT1H").then(function (pureCloudData) {
          if (granularity === "P1H") {
            resolve(pureCloudData);
          }
          var aggregatedData = {
            results: []
          };
          pureCloudData.results.forEach(function (result) {
            var aggregatedResult = {
              group: result.group,
              data: []
            };
            var aggregatedMap = {};
            result.data.forEach(function (data) {
              var intervalStart = moment(data.interval.split('/')[0]).local();
              var intervalKey = intervalStart.startOf("day").format();
              if (granularity === "P1W") {
                intervalKey = intervalStart.startOf("week").format();
              }
              if (!aggregatedMap[intervalKey]) {
                aggregatedMap[intervalKey] = {};
              }
              data.metrics.forEach(function (metric) {
                if (!aggregatedMap[intervalKey][metric.metric]) {
                  aggregatedMap[intervalKey][metric.metric] = {};
                }
                var keys = Object.keys(metric.stats);
                keys.forEach(function (key) {
                  if (aggregatedMap[intervalKey][metric.metric][key]) {
                    aggregatedMap[intervalKey][metric.metric][key] = aggregatedMap[intervalKey][metric.metric][key] + metric.stats[key];
                  } else {
                    aggregatedMap[intervalKey][metric.metric][key] = metric.stats[key];
                  }
                });
              });
            });
            Object.keys(aggregatedMap).forEach(function (intervalKey) {
              var interval = {
                interval: intervalKey,
                metrics: []
              };
              Object.keys(aggregatedMap[intervalKey]).forEach(function (metricKey) {
                var metric = {
                  metric: metricKey,
                  stats: aggregatedMap[intervalKey][metricKey]
                };
                interval.metrics.push(metric);
              });
              aggregatedResult.data.push(interval);
            });
            aggregatedData.results.push(aggregatedResult);
          });
          resolve(aggregatedData);
        }).catch(function (err) {
          reject();
          console.error("unable to get aggregate data for widget " + err);
        });
      });
    },
    processAggregateData: function processAggregateData(interval, previousPeriods, pureCloudData) {
      var _this = this;
      var configuration = this.get("configuration");
      var dataSetKey = configuration.dataSet;
      try {
        var labels = [];
        var datasets = {};
        var endVal = 1;
        if (configuration.interval === "P1D") {
          endVal = 0;
        }

        //if we are in a tz that isn't a full hour off of UTC
        if (moment.parseZone().utcOffset() % 60 != 0) {
          endVal = 1;
        }
        for (var x = previousPeriods - 1; x >= endVal; x--) {
          //debugger;
          var intervalStart = moment().endOf(interval).subtract(x, interval).format().split("T")[0];
          if (configuration.interval === "PT1H") {
            intervalStart = moment().startOf("hour").utc().subtract(x - 1, interval).format();
          } else if (configuration.interval === "P1W") {
            intervalStart = moment().endOf(interval).subtract(x, interval).add(1, "day").format().split("T")[0];
          }

          //moment().startOf(interval).subtract(x , interval).utc().format().split("T")[0];
          labels.push(intervalStart);
        }
        if (typeof pureCloudData.results === "undefined") {
          return;
        }
        pureCloudData.results.forEach(function (result) {
          try {
            for (var _x = 0; _x < result.data.length; _x++) {
              var data = result.data[_x];
              var _intervalStart = data.interval.split("T")[0];
              if (configuration.interval === "PT1H") {
                var time = moment(data.interval.split("/")[0]).utc();
                _intervalStart = time.format();
              }
              var dataSet = result.group[dataSetKey];
              if (typeof datasets[dataSet] === 'undefined') {
                datasets[dataSet] = {};
              }
              if (typeof datasets[dataSet][_intervalStart] === 'undefined') {
                var initialD = 1;
                if (configuration.type === "oServiceLevel") {
                  initialD = 0;
                }
                datasets[dataSet][_intervalStart] = {
                  n: 0,
                  d: initialD
                };
              }
              if (configuration.type === "oServiceLevel") {
                datasets[dataSet][_intervalStart].n = datasets[dataSet][_intervalStart].n + data.metrics[0].stats.numerator;
                datasets[dataSet][_intervalStart].d = datasets[dataSet][_intervalStart].d + data.metrics[0].stats.denominator;
              } else if (_this._isTimeData()) {
                datasets[dataSet][_intervalStart].n = datasets[dataSet][_intervalStart].n + data.metrics[0].stats.sum;
                datasets[dataSet][_intervalStart].d = datasets[dataSet][_intervalStart].d + data.metrics[0].stats.count;
              } else {
                datasets[dataSet][_intervalStart].n = datasets[dataSet][_intervalStart].n + data.metrics[0].stats.count;
              }
            }
          } catch (ex) {
            Ember.Logger.error(ex);
          }
        });
        if (configuration.chartType === "line" || configuration.chartType === "bar") {
          var data = {
            labels: labels,
            datasets: []
          };
          var colorIndex = 0;
          Object.keys(datasets).forEach(function (dataSetLabel) {
            var title = dataSetLabel;
            var queueNameMap = _this.get("queueService").queueIdToNameMap;
            var queueName = dataSetLabel;
            if (queueNameMap) {
              queueName = queueNameMap[dataSetLabel];
            }
            if (typeof queueName !== "undefined" && queueName !== null && queueName !== "") {
              title = queueName;
            }
            var backgrounColor = "transparent";
            if (configuration.chartType === "bar") {
              backgrounColor = _this.colors[colorIndex];
            }
            var dataset = {
              label: title,
              backgroundColor: backgrounColor,
              borderColor: _this.colors[colorIndex],
              pointBackgroundColor: _this.colors[colorIndex],
              data: []
            };
            colorIndex++;
            labels.forEach(function (label) {
              if (typeof datasets[dataSetLabel][label] !== "undefined" && datasets[dataSetLabel][label] !== null) {
                var val = datasets[dataSetLabel][label].n / datasets[dataSetLabel][label].d;
                if (_this._isTimeData()) {
                  val = val / SECONDS_FACTOR;
                } else if (datasets[dataSetLabel][label].d > 1) {
                  val = (val * 100).toFixed(2);
                }
                if (configuration.interval === "P1D") {
                  var time = moment(label);
                  if (configuration.hideWeekend !== true || configuration.hideWeekend === true && time.day() !== 0 && time.day() !== 6) {
                    dataset.data.push(val);
                  }
                } else {
                  dataset.data.push(val);
                }
              } else if (configuration.interval === "P1D") {
                var _time = moment(label);
                if (configuration.hideWeekend !== true || configuration.hideWeekend === true && _time.day() !== 0 && _time.day() !== 6) {
                  dataset.data.push(0);
                }
              } else {
                dataset.data.push(0);
              }
            });
            data.datasets.push(dataset);
          });
          if (configuration.interval === "PT1H") {
            var newLabels = [];
            labels.forEach(function (label) {
              newLabels.push(moment(label).format("h") + ":00");
            });
            data.labels = newLabels;
          } else if (configuration.interval === "P1D") {
            var _newLabels = [];
            labels.forEach(function (label) {
              var time = moment(label);
              if (configuration.hideWeekend !== true || configuration.hideWeekend === true && time.day() !== 0 && time.day() !== 6) {
                _newLabels.push(moment(label).format("dddd"));
              }
            });
            data.labels = _newLabels;
          } else if (configuration.interval === "P1M") {
            var _newLabels2 = [];
            labels.forEach(function (label) {
              _newLabels2.push(moment(label).format("MMM"));
            });
            data.labels = _newLabels2;
          }
          this.set("chartData", data);
        } else if (configuration.chartType === "pie") {
          var _data = {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: []
            }]
          };
          var _colorIndex = 0;
          Object.keys(datasets).forEach(function (dataSetLabel) {
            var title = dataSetLabel;
            var queueName = _this.get("queueService").queueIdToNameMap[dataSetLabel];
            if (typeof queueName !== "undefined" && queueName !== null && queueName !== "") {
              title = queueName;
            }
            _data.labels.push(title);
            var total = 0;
            Object.keys(datasets[dataSetLabel]).forEach(function (key) {
              var val = datasets[dataSetLabel][key];
              total = total + val;
            });
            _data.datasets[0].data.push(total);
            _data.datasets[0].backgroundColor.push(_this.colors[_colorIndex]);
            _colorIndex++;
          });
          Ember.Logger.log(_data);
          this.set("chartData", _data);
        }
      } catch (ex) {
        Ember.Logger.error(ex);
      }
    },
    createChart: function createChart() {
      var _this2 = this;
      var options = {
        title: {
          display: false
        },
        tooltips: {
          mode: 'index'
        },
        hover: {
          mode: 'index'
        },
        animation: {
          animateScale: false
        }
      };
      var previousPeriods = 9;
      var configuration = this.get("configuration");
      var realtimeService = this.get("realtimeService");
      var interval = "day";
      if (configuration.interval === "PT1H") {
        interval = "hour";
        previousPeriods = 12;
      } else if (configuration.interval === "P1W") {
        interval = "week";
      } else if (configuration.interval === "P1M") {
        interval = "month";
      }
      if (configuration.queueIds === null || configuration.queueIds.length === 0) {
        //Ember.set(this, 'options.title.text', 'No Queues');
        options.title.text = 'No Queues';
        return;
      }
      var graphend = moment().utc().endOf(interval).add(1, "ms");
      var graphstart = moment().utc().startOf(interval).subtract(previousPeriods, interval); //.add(1, "ms");

      //if we are in a tz that isn't a full hour off of UTC
      if (moment.parseZone().utcOffset() % 60 != 0) {
        graphstart = graphstart.add(1, "hour");
        graphend = graphend.add(1, "hour");
      }
      if (configuration.interval === "P1W") {
        graphend = graphend.add(1, "hour");
      }
      var metric = configuration.type.split('_')[0];
      if (configuration.interval === "P1D") {
        this.getAggregateDataWithTimezoneOffset(metric, graphstart, graphend, configuration.queueIds, configuration.interval).then(function (pureCloudData) {
          _this2.processAggregateData(interval, previousPeriods, pureCloudData);
        }).catch(function (err) {
          Ember.Logger.error("Falling back to legacy chart aggregation", err);
          realtimeService.getAggregateData(metric, graphstart, graphend, configuration.queueIds, configuration.interval).then(function (pureCloudData) {
            _this2.processAggregateData(interval, previousPeriods, pureCloudData);
          }).catch(function (err) {
            console.log.error("Unable to get chart data " + err);
          });
        });
      } else {
        realtimeService.getAggregateData(metric, graphstart, graphend, configuration.queueIds, configuration.interval).then(function (pureCloudData) {
          _this2.processAggregateData(interval, previousPeriods, pureCloudData);
        }).catch(function (err) {
          console.log.error("Unable to get chart data " + err);
        });
      }
      if (this && !this.isDestroyed) {
        var refreshInterval = 10 * 60 * 1000; //10 min

        if (configuration.interval === "PT1H") {
          refreshInterval = 3 * 60 * 1000; //3 min
        }
        Ember.run.later(this, function () {
          this.createChart();
        }, refreshInterval);
      }

      //Ember.set(this, 'options.title.text', configuration.title);
      options.title.text = configuration.title;
      var labelColor = "#666";
      if (this.get("darkMode") === true) {
        labelColor = "white";
      }
      Chart.defaults.global.defaultFontColor = labelColor;
      if (configuration.chartType !== "pie") {
        var labelString = null;
        var shouldStack = configuration.stackValues;
        if (configuration.type === "oServiceLevel" || this._isTimeData()) {
          shouldStack = false;
        }
        var yAxesCallback = function yAxesCallback(v) {
          return v.toLocaleString();
        };
        if (this._isTimeData()) {
          labelString = "Time in seconds";
        } else if (configuration.type === "oServiceLevel") {
          labelString = "%";
        }
        var scales = {
          xAxes: [{
            scaleLabel: {
              display: true
            },
            stacked: shouldStack
          }],
          yAxes: [{
            scaleLabel: {
              display: labelString !== null,
              labelString: labelString
            },
            stacked: shouldStack,
            ticks: {
              userCallback: yAxesCallback,
              min: 0
            }
          }]
        };
        options.scales = scales;
      } else {
        var _scales = {
          xAxes: [{
            scaleLabel: {
              display: false
            }
          }],
          yAxes: [{
            stacked: configuration.stackValues
          }]
        };
        options.scales = _scales;
      }
      this.set('options', options);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.createChart();
    },
    options: null
  });
});