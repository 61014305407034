define("insights-web/mixins/widget-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    fontSize: "",
    _setFontSizeSoon: function _setFontSizeSoon() {
      Ember.run.later(this, function () {
        this._setFontSize();
      }, 100);
    },
    heightChanged: Ember.observer('configuration.height', function () {
      this._setFontSizeSoon();
    }),
    calculateOptimalSize: function calculateOptimalSize(content) {
      var parentWidth = this.$().parents('.grid-stack-item-content').width();
      if (parentWidth < 100) {
        parentWidth = (window.outerWidth - 50) / 12 * this.configuration.width;
      }
      var dataLength = content.length;
      if (this.showPercent) {
        dataLength = dataLength + 2;
      }
      var fontSize = parentWidth * 1.65 / dataLength;
      var maxScalar = 30;
      var maxSize = this.configuration.height * (maxScalar + this.configuration.height * 3);
      if (fontSize > maxSize) {
        fontSize = maxSize;
      }
      return fontSize;
    },
    _updateTimer: function _updateTimer() {
      if (this.get("data") === 0 && this.configuration.zeroTimer === true) {
        var state = this.get("widgetStateService").getState(this.get("dashboardIndex"), this.get("widgetIndex"));
        if (state === null) {
          state = moment();
          this.get("widgetStateService").setState(this.get("dashboardIndex"), this.get("widgetIndex"), state);
        }
        var secondaryData = this._formatTimeString(Date.now() - state);
        this.set("secondaryData", secondaryData);
        var secondaryHeight = this.calculateOptimalSize(secondaryData) / 2;
        this.set('secondarysize', "".concat(secondaryHeight, "px"));
        if (this && !this.isDestroyed) {
          Ember.run.later(this, function () {
            this._updateTimer();
          }, 500);
        }
      }
    },
    _formatTimeString: function _formatTimeString(duration) {
      duration = duration / 1000;
      //Ember.Logger.log(duration);
      var seconds = parseInt(duration % 60);
      var minutes = parseInt(duration / 60 % 60);
      var hours = parseInt(duration / (60 * 60) % 24);
      if (isNaN(seconds)) {
        return "";
      }
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      return hours + ":" + minutes + ":" + seconds;
    },
    _setFontSize: function _setFontSize() {
      var fontSize = 50;
      var parentContainer = this.get('parentContainer');
      if (typeof parentContainer !== "undefined" && parentContainer !== null && typeof this.$() !== "undefined") {
        var parentWidth = this.$().parents('.grid-stack-item-content').width();
        var titleSize = 0;
        if (this.configuration && this.configuration.title) {
          if (parentWidth < 0) {
            parentWidth = window.outerWidth / 14.0 * this.configuration.width;
          }
          var titleLength = this.configuration.title.length;
          fontSize = parentWidth * 1.5 / titleLength;
          if (this.configuration.height === 2 && fontSize > 30) {
            fontSize = 30;
          } else if (fontSize > 45) {
            fontSize = 45;
          }
          this.configuration.titlesize = fontSize;
          titleSize = fontSize;
          if (typeof this.data !== "undefined" && this.data != null) {
            var _fontSize = this.calculateOptimalSize(this.data.toString());
            this.set('contentsize', "".concat(_fontSize, "px"));
            this.set('contentoffset', "-".concat(_fontSize / 6, "px"));
            // this.set('secondarysize', `${fontSize/2}px`);
            this.configuration.contentsize = _fontSize;
          }
        }
        if (this.configuration && this.configuration.useDefinedFontSize) {
          if (!this.configuration.definedFontSize) {
            this.configuration.definedFontSize = 24;
          }
          titleSize = this.configuration.definedFontSize;
        }
        this.set('titlesize', "".concat(titleSize, "px"));
        this.set('calculatedtitlesize', "".concat(titleSize, "px"));
        var parentHeight = this.$().parents('.grid-stack-item-content').height();
        var titleHeight = this.$().parents('.grid-stack-item-content').find(".widget-title").height();
        this.set('contentHeight', "".concat(parentHeight - titleHeight));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.configuration.contentsize) {
        this.set('contentsize', "".concat(this.configuration.contentsize, "px"));
        this.set('secondarysize', "".concat(this.configuration.contentsize / 2, "px"));
      }
      if (this.configuration.titlesize) {
        this.set('titlesize', "".concat(this.configuration.titlesize, "px"));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._setFontSizeSoon();
      Ember.run.later(this, function () {
        this._updateTimer();
      }, 500);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this._setFontSize();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var parentContainer = this.get('parentContainer');
      if (typeof parentContainer !== "undefined" && parentContainer !== null && parentContainer.$()) {
        parentContainer.$().off('resizestop');
      }
    }
  });
});