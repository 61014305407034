define("insights-web/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    requestService: Ember.inject.service(),
    embeddedService: Ember.inject.service(),
    missingPermissionService: Ember.inject.service(),
    isLoading: true,
    isEmbedded: false,
    init: function init() {
      this._super.apply(this, arguments);
      var self = this;
      var missingPermissionService = this.get("missingPermissionService");
      this.get("requestService").getMe().then(function (me) {
        self.set("isLoading", false);
        var hasQueueView = false;
        var hasConversationQuery = false;
        var hasConversationAggregate = false;
        var hasQueueObservation = false;
        var hasCampaignView = false;
        self.set("organizationId", me.organization.id);
        me.authorization.permissions.forEach(function (permission) {
          if (permission.match(/routing:queue.*view/g)) {
            hasQueueView = true;
          }
          if (permission.match(/analytics:conversationDetail:.*view/g)) {
            hasConversationQuery = true;
          }
          if (permission.match(/analytics:conversationAggregate:.*view/g)) {
            hasConversationAggregate = true;
          }
          if (permission.match(/analytics:queueObservation:.*view/g)) {
            hasQueueObservation = true;
          }
          if (permission.match(/outbound:campaign:.*view/g)) {
            hasCampaignView = true;
          }
        });
        if (!hasQueueView) {
          missingPermissionService.reportMissing("routing:queue:view");
        }
        if (!hasConversationQuery) {
          missingPermissionService.reportMissing("analytics:conversationDetail:view");
        }
        if (!hasConversationAggregate) {
          missingPermissionService.reportMissing("analytics:conversationAggregate:view");
        }
        if (!hasQueueObservation) {
          missingPermissionService.reportMissing("analytics:queueObservation:view");
        }
        missingPermissionService.set("hasCampaignView", hasCampaignView);
      });
      this.set('isEmbedded', this.get("embeddedService").isEmbedded());
    }
  });
});