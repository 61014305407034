define("insights-web/helpers/formatnumber", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatNumber = formatNumber;
  function formatNumber(params) {
    var value = params[0];

    //debugger;
    if (value % 1 !== 0) {
      value = parseFloat(parseFloat(value, 10).toFixed(2));
    }
    return value;
  }
  var _default = _exports.default = Ember.Helper.helper(formatNumber);
});