define("insights-web/components/wrapup-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    queueService: Ember.inject.service(),
    realtimeService: Ember.inject.service(),
    requestService: Ember.inject.service(),
    missingPermissionService: Ember.inject.service(),
    _getWrapupCodes: function _getWrapupCodes() {
      var self = this;
      var requestService = this.get("requestService");
      Ember.run.later(this, function () {
        this._getWrapupCodes();
      }, 1000 * 60 * 60); //every hour

      if (['c47c6696-d16f-4e1a-80d5-e253aadf356e',
      //focuscura https://mypureinsights.zendesk.com/agent/tickets/1270
      'c78e7a69-926d-4ad4-a90e-736a8894e089',
      //sodexo https://mypureinsights.zendesk.com/agent/tickets/1632
      '803ac76d-37cc-4485-a56e-4d4c8c4b6a41',
      //crystalsas https://mypureinsights.zendesk.com/agent/tickets/5236
      '924246ee-e90f-42c1-96be-53a8f5f51c45',
      //europassistancebe https://mypureinsights.zendesk.com/agent/tickets/6744
      ''].indexOf(this.get("requestService").me.organization.id) > -1) {
        //wrapup codes cause a high api usage, some customers ask to disable
        this.set("wrapupwarning", "Wrap up code widget is not supported for this organization");
        return Ember.RSVP.Promise.resolve();
      }
      return new Ember.RSVP.Promise(function (resolve) {
        requestService.getResource("/purecloud/wrapups").then(function (wrapups) {
          var wrapupMap = {};
          wrapups.forEach(function (wrapup) {
            wrapupMap[wrapup.id] = wrapup.name;
          });
          self.set("wrapupMap", wrapupMap);
          resolve();
        });
      });
    },
    _getWrapupData: function _getWrapupData() {
      var self = this;
      Ember.run.later(this, function () {
        if (this && !this.isDestroyed) {
          this._getWrapupData();
        }
      }, 20 * 1000); //every 20 seconds

      var configuration = this.get("configuration");
      var realtimeService = this.get("realtimeService");
      var end = moment().endOf("day");
      var start = moment().startOf("day");
      realtimeService.getAggregateData("tHandle", start, end, configuration.queueIds, "P1D", ["wrapUpCode", "queueId"]).then(function (values) {
        var wrapupMap = self.get("wrapupMap");
        var displayValueMap = {};
        var displayValues = [];
        if (values.results) {
          values.results.forEach(function (result) {
            if (!result.group.wrapUpCode) {
              return;
            }
            var display = wrapupMap[result.group.wrapUpCode];
            if (!display) {
              if (result.group.wrapUpCode.length === 36) {
                display = "Default";
              } else if (result.group.wrapUpCode === 'ININ-OUTBOUND-PREVIEW-SKIPPED') {
                display = "Outbound Preview Skipped";
              } else if (result.group.wrapUpCode === 'ININ-OUTBOUND-TRANSFERRED-TO-QUEUE') {
                display = "Outbound Transferred To Queue";
              } else if (result.group.wrapUpCode === 'ININ-OUTBOUND-NO-ANSWER') {
                display = "Outbound No Answer";
              } else if (result.group.wrapUpCode === 'ININ-OUTBOUND-DISCONNECT') {
                display = "Outbound Disconnect";
              } else {
                display = result.group.wrapUpCode;
              }
            }
            if (!displayValueMap[display]) {
              displayValueMap[display] = 0;
            }
            result.data.forEach(function (data) {
              data.metrics.forEach(function (metric) {
                if (metric.stats && metric.stats.count) {
                  displayValueMap[display] = displayValueMap[display] + metric.stats.count;
                }
              });
            });
          });
          Object.keys(displayValueMap).forEach(function (key) {
            displayValues.push({
              code: key,
              value: displayValueMap[key]
            });
          });
        }
        displayValues = displayValues.sort(function (a, b) {
          if (a.value === b.value) {
            return 0;
          } else if (a.value < b.value) {
            return 1;
          }
          return -1;
        });
        if (displayValues.length > configuration.count) {
          displayValues = displayValues.slice(0, configuration.count);
        }
        self.set("wrapups", displayValues);
      }).catch(function (ex) {
        console.error("Unable to get wrapup data " + ex);
      });

      // realtimeService.getConversationSummary("wrapUpCode", configuration.count, start, end, configuration.queueIds, 1).then(function(values){
      //     let wrapupMap = self.get("wrapupMap");
      //     let displayValues = [];

      //     if(values.aggregations){
      //         let wrapupAggregation = values.aggregations[0];
      //         wrapupAggregation.results.forEach((aggregation)=>{
      //             let display = wrapupMap[aggregation.value];
      //             if (!display){
      //                 if(aggregation.value.length === 36){
      //                     display = "Default";
      //                 }else if(aggregation.value === 'ININ-OUTBOUND-PREVIEW-SKIPPED'){
      //                     display = "Outbound Preview Skipped";
      //                 }else if(aggregation.value === 'ININ-OUTBOUND-TRANSFERRED-TO-QUEUE'){
      //                     display = "Outbound Transferred To Queue";
      //                 }else if(aggregation.value === 'ININ-OUTBOUND-NO-ANSWER'){
      //                     display = "Outbound No Answer";
      //                 }else if(aggregation.value === 'ININ-OUTBOUND-DISCONNECT'){
      //                     display = "Outbound Disconnect";
      //                 }else{
      //                     display = aggregation.value;
      //                 }
      //             }
      //             displayValues.push({
      //                 code: display,
      //                 value: aggregation.count
      //             });
      //         });
      //     }

      //     displayValues = displayValues.sort((a,b)=>{
      //         if(a.value === b.value){
      //             return 0;
      //         }else if(a.value < b.value){
      //             return 1;
      //         }

      //         return -1;
      //     });
      //     self.set("wrapups", displayValues);
      // });
    },
    queuesChanged: Ember.observer('queueService.queueList', function () {
      var self = this;
      this._getWrapupCodes().then(function () {
        self._getWrapupData();
      });
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;
      this._getWrapupCodes().then(function () {
        self._getWrapupData();
      });
    }
  });
});