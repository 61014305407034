define("insights-web/services/diagnostics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    queueService: Ember.inject.service(),
    realtimeService: Ember.inject.service(),
    send: function send() {
      // let queueList = this.get("queueService").queueList;
      // let dashboardConfiguration = this.get('realtimeService').get("dashboardConfiguration");
      // let historicalData = this.get('realtimeService').get("queueData");
      // let observationData = this.get('realtimeService').get("observationData");
      // let gatedObservationData = this.get('realtimeService').get("gatedObservationData");
    }
  });
});