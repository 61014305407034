define("insights-web/helpers/formattime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formattime = formattime;
  function formattime(params) {
    var value = params[0];
    return moment(value).format("LLLL");
  }
  var _default = _exports.default = Ember.Helper.helper(formattime);
});