define("insights-web/components/queue-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    requestService: Ember.inject.service(),
    queueService: Ember.inject.service(),
    storageService: Ember.inject.service(),
    isLoading: true,
    selectedQueueNames: [],
    description: "Select which queues are included in the result set.",
    title: 'Queues',
    valuesChanged: Ember.observer('queueService.queueMap', 'selectedQueueNames', 'selectedQueueNames.@each', function () {
      var selectedQueueNames = this.get("selectedQueueNames");
      var availableQueueIds = [];
      var availableQueueNames = [];
      var map = this.get('queueService').get('queueMap');
      if (map) {
        this.set("isLoading", false);
      }
      var allKeys = Object.keys(map); //map of name - id pair

      for (var x = allKeys.length - 1; x >= 0; x--) {
        var queueName = allKeys[x];
        var queueId = map[queueName];
        if (selectedQueueNames.indexOf(queueName) === -1) {
          availableQueueIds.push(queueId);
          availableQueueNames.push(queueName);
        }
      }
      selectedQueueNames.sort();
      availableQueueNames.sort();
      var newQueueIds = [];
      selectedQueueNames.forEach(function (queueName) {
        for (var _x = allKeys.length - 1; _x >= 0; _x--) {
          if (queueName === allKeys[_x]) {
            var _queueId = map[queueName];
            newQueueIds.pushObject(_queueId);
          }
        }
      });
      this.selectedQueueIds.clear();
      this.selectedQueueIds.pushObjects(newQueueIds);

      //    this.set("selectedQueueIds", selectedQueueIds);
      this.set("availableQueueIds", availableQueueIds);
      this.set("availableQueueNames", availableQueueNames);
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.get("queueService");
      // },
      // didReceiveAttrs(){
      //   this._super(...arguments);

      var selectedQueueIds = this.get("selectedQueueIds");
      var selectedQueueNames = [];
      var availableQueueIds = [];
      var availableQueueNames = [];
      var map = this.get('queueService').get('queueMap');
      if (!map) {
        return;
      }
      var allKeys = Object.keys(map); //map of name - id pair

      if (allKeys.length > 0) {
        this.set("isLoading", false);
      }
      for (var x = allKeys.length - 1; x >= 0; x--) {
        var queueName = allKeys[x];
        var queueId = map[queueName];
        if (selectedQueueIds.indexOf(queueId) > -1) {
          selectedQueueNames.pushObject(queueName);
        } else {
          availableQueueIds.pushObject(queueId);
          availableQueueNames.pushObject(queueName);
        }
      }
      selectedQueueNames.sort();
      availableQueueNames.sort();
      this.set("selectedQueueNames", selectedQueueNames);
      this.set("availableQueueIds", availableQueueIds);
      this.set("availableQueueNames", availableQueueNames);
    }
  });
});