define("insights-web/templates/dashboard-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jJuGrrS7",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,0],[[32,0],\"update\"],null],[12],[2,\"Save\"],[13],[2,\"\\n\"],[1,[30,[36,2],null,[[\"rows\",\"cols\",\"value\"],[\"30\",\"100\",[35,1]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"config\",\"textarea\"]}",
    "meta": {
      "moduleName": "insights-web/templates/dashboard-config.hbs"
    }
  });
});