define("insights-web/utils/widgetstattypes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var widgetstattypes = [{
    "key": "average_call_time",
    "text": "Average Conversation Time",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": false,
    "metrics": ["tTalkComplete"],
    "category": "Queue"
  }, {
    "key": "conversations_active",
    "text": "Active Conversations",
    "widgetType": "count",
    "multiqueue": true,
    "allowZeroTimer": true,
    "isObservation": true,
    "metrics": ["oInteracting"],
    "category": "Queue"
  }, {
    "key": "agents_on_queue",
    "text": "Agents on Queue",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": true,
    "metrics": ["oOnQueueUsers", "oUserRoutingStatuses"],
    "category": "Queue",
    "agentStat": true
  }, {
    "key": "agents_interacting",
    "text": "Agents Interacting",
    "qualifier": "INTERACTING",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": true,
    "metrics": ["oOnQueueUsers", "oUserRoutingStatuses"],
    "category": "Queue",
    "agentStat": true
  }, {
    "key": "agents_idle",
    "text": "Agents Idle",
    "widgetType": "count",
    "qualifier": "IDLE",
    "multiqueue": true,
    "isObservation": true,
    "metrics": ["oOnQueueUsers", "oUserRoutingStatuses"],
    "category": "Queue",
    "agentStat": true
  }, {
    "key": "average_wait_time",
    "text": "Average Wait Time",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": false,
    "metrics": ["tAcd"],
    "category": "Queue"
  }, {
    "key": "average_speed_of_answer",
    "text": "Average Speed Of Answer",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": false,
    "metrics": ["tAnswered"],
    "category": "Queue"
  }, {
    "key": "conversations_waiting",
    "text": "Conversations Waiting",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": true,
    "metrics": ["oWaiting"],
    "category": "Queue"
  }, {
    "key": "total_conversations",
    "text": "Conversation Offered Count",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": false,
    "metrics": ["nOffered"],
    "category": "Queue"
  }, {
    "key": "total_conversations_answered",
    "text": "Conversation Answered Count",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": false,
    "metrics": ["tAnswered"],
    "category": "Queue"
  }, {
    "key": "total_conversations_answered_pct",
    "text": "Conversation Answered %",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": false,
    "metrics": ["tAnswered", "nOffered"],
    "category": "Queue"
  }, {
    "key": "sla_today",
    "text": "SLA",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": false,
    "metrics": ["oServiceLevel"],
    "category": "Queue"
  }, {
    "key": "campaign_progress",
    "text": "Campaign Progress %",
    "widgetType": "count",
    "isCampaign": true,
    "category": "Campaign"
  }, {
    "key": "campaign_idleagents",
    "text": "Campaign Idle Agents",
    "widgetType": "count",
    "isCampaign": true,
    "category": "Campaign"
  }, {
    "key": "campaign_contactrate",
    "text": "Campaign Contact Rate",
    "widgetType": "count",
    "isCampaign": true,
    "isObservation": true,
    "metrics": ["oContactRate"],
    "category": "Campaign"
  },
  /*{
    "key": "queue_conversation_state_by_mediatype",
    "text": "Queue Conversation State by Media Type (Bar Chart)",
    "widgetType": "barchart",
    "multiqueue": true,
    "isObservation": true
  },
  {
    "key": "queue_mediatype",
    "text": "Queue Media Types (Pie Chart)",
    "widgetType": "piechart",
    "multiqueue": true,
    "isObservation": true,
    "metrics": ["oInteracting", "oWaiting"]
  },*/
  {
    "key": "queue_depth",
    "text": "Queue Depth",
    // (Ratio of waiting conversations to agents in queue)",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": true,
    "metrics": ["oOnQueueUsers", "oWaiting"],
    "category": "Queue"
  }, {
    "key": "abandoned_interactions",
    "text": "Abandoned Interactions",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": false,
    "metrics": ["tAbandon"],
    "category": "Queue"
  }, {
    "key": "longest_call_waiting",
    "text": "Longest Call Waiting",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": true,
    "metrics": [],
    "category": "Queue"
  }, {
    "key": "average_handle_time",
    "text": "Average Handle Time",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": false,
    "metrics": ["tHandle"],
    "category": "Queue"
  }, {
    "key": "average_hold_time",
    "text": "Average Hold Time",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": false,
    "metrics": ["tHeldComplete"],
    "category": "Queue"
  }, {
    "key": "average_acw_time",
    "text": "Average ACW Time",
    "widgetType": "count",
    "multiqueue": true,
    "isObservation": false,
    "metrics": ["tAcw"],
    "category": "Queue"
  }];
  var _default = _exports.default = widgetstattypes;
});