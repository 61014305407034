define("insights-web/components/web-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    iframeHeight: 100,
    heightChanged: Ember.observer('configuration.height', function () {
      var parentHeight = this.$().parents('.grid-stack-item-content').height();
      this.set("iframeHeight", parentHeight);
    }),
    didInsertElement: function didInsertElement() {
      Ember.run.later(this, function () {
        var parentHeight = this.$().parents('.grid-stack-item-content').height();
        this.set("iframeHeight", parentHeight);
      }, 100);
    }
  });
});