define("insights-web/components/stat-grid-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    values: [],
    columns: [],
    getColumnStyle: function getColumnStyle(index) {
      if (index === 0) {
        return "col-xs-12 col-sm-3 col-md-2 col-lg-2";
      }
      if (index <= 3) {
        return "col-sm-3 col-md-1 hidden-xs";
      }
      if (index <= 6) {
        return "col-md-2 hidden-xs hidden-sm";
      }
      return "col-lg-1 hidden-xs hidden-md hidden-sm";
    },
    init: function init() {
      this._super.apply(this, arguments);
      var columns = this.get("columns");
      this.values.clear();
      debugger;
      for (var x = 0; x < columns.length; x++) {
        this.values.pushObject({
          text: columns[x],
          style: this.getColumnStyle(x)
        });
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.get("columns");
      var columns = this.get("columns");
      this.values.clear();
      for (var x = 0; x < columns.length; x++) {
        this.values.pushObject({
          text: columns[x],
          style: this.getColumnStyle(x)
        });
      }
    },
    queueNameObserver: Ember.observer('columns', 'columns.@each', 'columns.[]', function () {
      var columns = this.get("columns");
      this.values.clear();
      for (var x = 0; x < columns.length; x++) {
        this.values.pushObject({
          text: columns[x],
          style: this.getColumnStyle(x)
        });
      }
    })
  });
});