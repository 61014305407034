define("insights-web/templates/debug-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MutgiKRU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h3\"],[12],[2,\"Input query here\"],[13],[2,\"\\n\"],[1,[30,[36,1],null,[[\"cols\",\"rows\",\"value\"],[\"80\",\"6\",[35,0,[\"query\"]]]]]],[2,\"\\n\\n\"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,2],[[32,0],\"run\"],null],[12],[2,\" Run Query\"],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,0,[\"results\"]]],null,[[\"default\"],[{\"statements\":[[10,\"h3\"],[12],[2,\"Results\"],[13],[2,\"\\n\"],[1,[35,0,[\"results\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"textarea\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "insights-web/templates/debug-query.hbs"
    }
  });
});