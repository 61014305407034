define("insights-web/components/conversation-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    requestService: Ember.inject.service(),
    conversationTimeline: [["Participant", "Segment", "Start", "End"]],
    _parseConversation: function _parseConversation(conversation, self) {
      //let conversationData=[["Participant", "Segment", "Start", "End"]];
      Ember.Logger.log(conversation);
      for (var x = 0; x < conversation.participants.length; x++) {
        var participant = conversation.participants[x];
        var name = participant.participantName || participant.purpose;
        if (participant.attributes && participant.attributes.length > 0) {
          self.set('hasAttributes', true);
        }
        for (var s = 0; s < participant.sessions.length; s++) {
          var session = participant.sessions[s];
          for (var y = 0; y < session.segments.length; y++) {
            var segment = session.segments[y];
            var start = moment(segment.segmentStart);
            var end = moment();
            if (segment.segmentEnd) {
              end = moment(segment.segmentEnd);
            }
            self.conversationTimeline.pushObject([name, segment.segmentType, start.toDate(), end.toDate()]);
          }
        }
      }
      var duration = moment(conversation.participants[0].sessions[0].segments[conversation.participants[0].sessions[0].segments.length - 1].segmentEnd) - moment(conversation.participants[0].sessions[0].segments[0].segmentStart);
      if (duration > 1000) {
        self.set('hasData', true);
      }
    },
    options: {
      timeline: {
        groupByRowLabel: true
      },
      height: 300
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var self = this;
      self.set("conversation", null);
      self.conversationTimeline.clear();
      self.conversationTimeline.pushObject(["Participant", "Segment", "Start", "End"]);
      this.get("requestService").getResource('/purecloud/conversation/' + this.get("conversationId")).then(function (conversation) {
        self.set("conversation", conversation);
        self.set("conversationJson", JSON.stringify(conversation, null, 2));
        self._parseConversation(conversation, self);
      });
    }
  });
});