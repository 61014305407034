define("insights-web/components/static-text-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.later(this, function () {
        if (this.configuration.scrollText) {
          this.marquee(this.$().parents('.grid-stack-item-content'), this.$().children(".static-text-widget"));
        }
        this.set("loaded", true);
        var parentHeight = this.$().parents('.grid-stack-item-content').height();
        this.set('contentTop', "".concat(parentHeight / 2 - 50, "px"));
      }, 500);
    },
    marquee: function marquee(a, b) {
      var self = this;
      b.css({
        'white-space': 'nowrap'
      });
      var width = b.width();
      var start_pos = a.width();
      var end_pos = -width;
      function scroll() {
        if (b.position().left <= -width + 1) {
          b.css('left', start_pos);
          scroll();
        } else {
          var scrollSpeed = 5;
          if (self.configuration.scrollSpeed) {
            scrollSpeed = Math.abs(16 - self.configuration.scrollSpeed);
          }
          var time = (parseInt(b.position().left, 10) - end_pos) * (scrollSpeed * 3000 / (start_pos - end_pos)); // Increase or decrease speed by changing value 10000, bigger = slower
          b.animate({
            'left': -width
          }, time, 'linear', function () {
            scroll();
          });
        }
      }
      b.css({
        'width': width,
        'left': start_pos,
        'white-space': 'nowrap'
      });
      scroll(a, b);
    }
  });
});