define("insights-web/templates/components/stat-grid-row-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uyeM+7T8",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,0,\"row-toggle\"],[4,[38,2],[[32,0],[30,[36,1],[\"expanded\",[35,0]],null]],null],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[\"fa \",[30,[36,3],[[35,0,[\"expanded\"]],\"fa-chevron-down\",\"fa-chevron-right\"],null]]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"row\",\"toggle\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/stat-grid-row-toggle.hbs"
    }
  });
});