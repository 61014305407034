define("insights-web/components/queue-stats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    queueService: Ember.inject.service(),
    statCalculation: Ember.inject.service(),
    realtimeService: Ember.inject.service(),
    timeDiff: Ember.inject.service(),
    expander: [],
    _queueIds: null,
    _stats: null,
    data: null,
    isLoading: true,
    headers: [],
    init: function init() {
      this._super.apply(this, arguments);
    },
    updateTimers: function updateTimers() {
      Ember.run.later(this, function () {
        this.updateTimers();
      }, 1000); //every second

      this._loadData();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.get("realtimeService").get("queueData");
      this.get("realtimeService").get("observationData");
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var configuration = this.get("configuration");
      var _queueIds = [];
      configuration.queueIds.forEach(function (queueId) {
        _queueIds.pushObject(queueId);
      });
      this.set("_queueIds", _queueIds);
      this.set("_stats", []);
      configuration.stats.forEach(function (stat) {
        _this._stats.pushObject(stat);
      });
      if (!configuration.fontsize) {
        configuration.fontsize = "18";
      }
      this._loadData();
      for (var x = 0; x < this._stats.length; x++) {
        if (this._stats[x] === "longest_call_waiting") {
          //need to refresh the UI on a quicker timer
          this.updateTimers();
        }
      }
    },
    queueNameObserver: Ember.observer('configuration', 'configuration.queueIds', 'configuration.queueIds.@each', function () {
      var _this2 = this;
      this._queueIds.clear();
      this.configuration.queueIds.forEach(function (queueId) {
        _this2._queueIds.pushObject(queueId);
      });
    }),
    _formatTimeString: function _formatTimeString(start, end) {
      start = this.get("timeDiff").skewAdjustedTime(start);
      var diff = moment.duration(end.diff(start));

      //Get hours and subtract from duration
      var hours = diff.hours();
      diff.subtract(moment.duration(hours, 'hours'));
      if (diff.days() > 0) {
        hours = hours + 24 * diff.days();
      }

      //Get Minutes and subtract from duration
      var minutes = diff.minutes();
      diff.subtract(moment.duration(minutes, 'minutes'));

      //Get seconds
      var seconds = diff.seconds();
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
    },
    _loadData: function _loadData() {
      var startTime = moment().startOf('day').utc().toISOString();
      this.set("isLoading", false);
      var statCalculation = this.get("statCalculation");
      var queueService = this.get("queueService");
      var _data = [];
      while (this.expander.length < this._queueIds.length) {
        this.expander.pushObject(false);
      }
      var mediaType = this.configuration.mediaType;
      if (queueService.queueIdToNameMap) {
        for (var y = 0; y < this._queueIds.length; y++) {
          var queueId = this._queueIds[y];
          var rowData = {
            isExpanded: this.expander[y],
            stats: [{
              value: queueService.queueIdToNameMap[queueId]
            }]
          };
          for (var x = 0; x < this._stats.length; x++) {
            var statKey = this._stats[x];
            var val = 0;
            var statValue = statCalculation.getStatValue([queueId], statKey, startTime, mediaType);
            if (statValue) {
              val = statValue.data;
              if (statValue.isTimeSince === true && statValue.data) {
                val = this._formatTimeString(statValue.data, moment());
              }
            }
            rowData.stats.push({
              value: val,
              column: statCalculation.getStatDisplayName(statKey)
            });
          }
          _data.push(rowData);
        }
      }
      var headers = [];
      headers.push("Queue");
      for (var _x = 0; _x < this._stats.length; _x++) {
        var _statKey = this._stats[_x];
        headers.push(statCalculation.getStatDisplayName(_statKey));
      }
      this.set("headers", headers);
      this.set("data", _data);
    },
    dataObserver: Ember.observer('realtimeService.queueData', 'realtimeService.observationData', 'queueService.queueIdToNameMap', function () {
      this._loadData();
    }),
    actions: {
      toggleExpand: function toggleExpand(index) {
        this.expander.arrayContentWillChange(index);
        this.expander[index] = !this.expander[index];
        Ember.set(this.data[index], 'isExpanded', this.expander[index]);
        ///    Ember.set(this.expander, `total.${year}`, data.total);
      }
    }
  });
});