define("insights-web/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    analyticsService: Ember.inject.service(),
    requestService: Ember.inject.service(),
    errorService: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var self = this;
      // define a new console
      var console = function (oldCons) {
        return {
          log: function log(text) {
            oldCons.log(text);
            // Your code
          },
          info: function info(text) {
            oldCons.info(text);
          },
          warn: function warn(text) {
            oldCons.warn(text);
          },
          error: function error(text) {
            oldCons.error(text);
            self.get("errorService").logError(text, "");
          }
        };
      }(window.console);

      //Then redefine the old console
      window.console = console;
      Ember.Logger = console;
      return this.get('analyticsService');
    }
  });
});