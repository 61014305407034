define("insights-web/utils/graphconfigoptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  //  configuration
  chartTYpe: bar, line
  Stat. nOffered, nAbandoned, tHandled, tWait, serviceLevel
  Interval : Day, week, Month
  Group By: Queue, Media Type
  Stacked t/f
  Queues
  
  */

  var graphconfigoptions = {
    chartTypes: [{
      'text': '',
      'key': ''
    }, {
      'text': 'Line',
      'key': 'line'
    }, {
      'text': 'Bar',
      'key': 'bar'
    }],
    stats: [{
      'text': '',
      'key': ''
    }, {
      'text': 'Average Hold Time',
      'key': 'tHeldComplete'
    }, {
      'text': 'Average Speed of Abandon',
      'key': 'tAbandon'
    }, {
      'text': 'Average Speed of Answer',
      'key': 'tAnswered'
    }, {
      'text': 'Average Wait Time',
      'key': 'tAcd'
    }, {
      'text': 'Interactions Abandoned',
      'key': 'tAbandon_count'
    }, {
      'text': 'Interactions Answered',
      'key': 'tAnswered_count'
    }, {
      'text': 'Interactions Offered',
      'key': 'nOffered'
    }, {
      'text': 'SLA',
      'key': 'oServiceLevel'
    }],
    intervals: [{
      'text': '',
      'key': ''
    }, {
      'text': 'Hour',
      'key': 'PT1H'
    }, {
      'text': 'Day',
      'key': 'P1D'
    }, {
      'text': 'Week',
      'key': 'P1W'
    } /*,
      {
         'text': 'Month',
         'key' : 'P1M'
      }*/],
    dataSet: [{
      'text': '',
      'key': ''
    }, {
      'text': 'Queue',
      'key': 'queueId'
    }, {
      'text': 'Media Type',
      'key': 'mediaType'
    }]
  };
  var _default = _exports.default = graphconfigoptions;
});