define("insights-web/components/widget-clock", ["exports", "insights-web/mixins/widget-mixin"], function (_exports, _widgetMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_widgetMixin.default, {
    hourformat24: null,
    _updateTime: function _updateTime() {
      var format = "hh:mm a";
      var hourformat24 = this.get("configuration").hourformat24;
      if (typeof hourformat24 !== "undefined" && hourformat24 === true) {
        format = "HH:mm";
      }
      var time = moment().tz(this.get("configuration").title);
      if (this && !this.isDestroyed) {
        this.set("data", time.format(format));
        this._setFontSize();
        Ember.run.later(this, function () {
          this._updateTime();
        }, 1000);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      Ember.run.later(this, function () {
        this._updateTime();
      }, 100);
    }
  });
});