define("insights-web/helpers/eq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var eq = function eq(params) {
    return params[0] === params[1];
  };
  var _default = _exports.default = Ember.Helper.helper(eq);
});