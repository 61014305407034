define("insights-web/services/embedded-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    isEmbedded: function isEmbedded() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  });
});