define("insights-web/components/timezone-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    hourformat24: null,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var self = this;
      setInterval(function () {
        var format = "hh:mm a";
        var hourformat24 = _this.get("hourformat24");
        if (typeof hourformat24 !== "undefined" && hourformat24 === true) {
          format = "HH:mm";
        }

        //.zoneAbbr()
        var est = moment().tz("America/New_York");
        var cst = moment().tz("America/Chicago");
        var mst = moment().tz("America/Denver");
        var pst = moment().tz('America/Los_Angeles');
        if (!_this.isDestroyed) {
          self.set("eastern", est.format(format));
          self.set("central", cst.format(format));
          self.set("mountain", mst.format(format));
          self.set("pacific", pst.format(format));
          self.set("utc", moment().utc().format(format));
        }
      }, 1000);
    }
  });
});