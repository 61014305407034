define("insights-web/templates/components/configuration/image-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mwxOHic/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-md-1\"],[12],[2,\"\\n            \"],[10,\"label\"],[12],[2,\"Image Url\"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"col-md-10\"],[12],[2,\"\\n            \"],[1,[30,[36,1],null,[[\"class\",\"value\"],[\"form-control\",[35,0,[\"title\"]]]]]],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[35,0,[\"title\"]],\"https\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"             \"],[10,\"span\"],[14,0,\"text-danger\"],[12],[2,\"\\n                URL must start with https.\\n             \"],[13],[2,\"\\n            \\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\\n    \"],[13]],\"hasEval\":false,\"upvars\":[\"editWidget\",\"input\",\"contains\",\"unless\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/configuration/image-widget.hbs"
    }
  });
});