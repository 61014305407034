define("insights-web/templates/components/call-detail-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tmXd8r+q",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"a\"],[15,6,[31,[\"https://apps.mypurecloud.com/directory/#/engage/admin/interactions/\",[34,0]]]],[14,\"targe\",\"_blank\"],[12],[18,1,null],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"conversationId\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/call-detail-link.hbs"
    }
  });
});