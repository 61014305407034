define("insights-web/components/widget-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    didRender: function didRender() {
      Ember.run.later(this, function () {
        var parentHeight = this.$().parents('.grid-stack-item-content').height();
        var parentWidth = this.$().parents('.grid-stack-item-content').width();
        this.$("img").css('max-height', "".concat(parentHeight - 20, "px"));
        this.$("img").css('max-width', "".concat(parentWidth - 20, "px"));
      }, 1000);
      this.$().parents('.grid-stack-item-content').css('background-color', 'transparent');
      this.$().parents('.grid-stack-item-content').css('border', '0px');
    }
  });
});