define("insights-web/controllers/dashboard-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    realtimeService: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.get("realtimeService").getDashboards().then(function (config) {
        _this.set("config", JSON.stringify(config, null, " "));
      });
    },
    actions: {
      update: function update() {
        var _this2 = this;
        var config = JSON.parse(this.get("config"));
        this.get("realtimeService").saveDashboards(config).then(function () {
          _this2.get('notifications').success('Saved successfully', {
            autoClear: true,
            clearDuration: 1200
          });
        });
      }
    }
  });
});