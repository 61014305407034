define("insights-web/templates/components/trial-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pJJKTYw4",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"alert alert-info\"],[14,\"role\",\"alert\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[2,\"Trial\"],[13],[2,\"\\n    You have \"],[10,\"strong\"],[12],[1,[34,0]],[2,\" days\"],[13],[2,\" left in your trial.\\n\\n    \"],[10,\"b\"],[12],[10,\"a\"],[14,0,\"app-foundry-link\"],[14,\"target\",\"_blank\"],[14,6,\"https://appfoundry.mypurecloud.com/filter/purecloud/listing/1f3df1a1-1aae-4439-b235-376062fc0b14\"],[12],[2,\"Purchase on the AppFoundry\"],[13],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[3,\"show in purecloudnow \"],[2,\"\\n\"],[6,[37,2],[[30,[36,4],[[35,3],\"f657a486-0a31-413f-bcf9-f453b0b3d6db\"],null]],null,[[\"default\"],[{\"statements\":[[10,\"b\"],[12],[10,\"a\"],[14,0,\"app-foundry-link\"],[14,\"target\",\"_blank\"],[14,6,\"https://appfoundry.mypurecloud.com/filter/purecloud/listing/1f3df1a1-1aae-4439-b235-376062fc0b14\"],[12],[2,\"Purchase on the AppFoundry\"],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"daysRemaining\",\"isTrial\",\"if\",\"organizationId\",\"eq\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/trial-banner.hbs"
    }
  });
});