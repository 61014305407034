define("insights-web/components/configuration/observation-graph", ["exports", "insights-web/utils/observationGraphStatTypes"], function (_exports, _observationGraphStatTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    availableStats: [],
    selectedStats: [],
    queueService: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.set("queues", this.get("queueService").queueList);
    },
    valuesChanged: Ember.observer('queueService.queueList', 'queueService.queueList.[]', 'queueService.queueList.@each', function () {
      this.set("queues", this.get("queueService").queueList);
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      if (!this.get("editWidget")) {
        return;
      }
      if (!this.get("editWidget").stats) {
        this.get("editWidget").stats = [];
      }
      this.availableStats.clear();
      _observationGraphStatTypes.default.forEach(function (type) {
        _this.availableStats.pushObject(type.text);
      });
      var selected = [];
      this.get("editWidget").stats.forEach(function (configuredKey) {
        _observationGraphStatTypes.default.forEach(function (type) {
          if (type.key === configuredKey) {
            if (selected.indexOf(type.text) === -1) {
              selected.push(type.text);
            }
            _this.availableStats.removeObject(type.text);
          }
        });
      });
      this.selectedStats.setObjects(selected);
      if (!this.get("editWidget").duration || this.get("editWidget").duration === "") {
        this.get("editWidget").duration = 1;
      }
    },
    configuredStatsObserver: Ember.observer('selectedStats', 'selectedStats.[]', 'selectedStats.@each', function () {
      var _this2 = this;
      this.get("editWidget").stats.clear();
      this.selectedStats.forEach(function (selectedName) {
        _observationGraphStatTypes.default.forEach(function (type) {
          if (type.text === selectedName) {
            _this2.get("editWidget").stats.push(type.key);
          }
        });
      });
    })
  });
});