define("insights-web/templates/components/stat-grid-row-expanded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/++sqW55",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[14,5,\"font-size:15px\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"responsiveHiddenColumns\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-xs-1\"],[12],[2,\"\\n            \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-xs-6\"],[12],[2,\"\\n            \"],[10,\"label\"],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"col-xs-5\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[[35,0],[32,1,[\"valuePath\"]]],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"row\",\"get\",\"table\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/stat-grid-row-expanded.hbs"
    }
  });
});