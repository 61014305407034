define("insights-web/templates/components/dnis-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "srJguLS4",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,[[\"title\",\"description\"],[[35,3],[35,2]]],[[\"default\"],[{\"statements\":[[1,[30,[36,1],null,[[\"value\",\"class\"],[[35,0],\"form-control \"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dnisValue\",\"input\",\"description\",\"title\",\"query-panel\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/dnis-value.hbs"
    }
  });
});