define("insights-web/components/bs-datetimepicker", ["exports", "ember-cli-bootstrap-datetimepicker/components/bs-datetimepicker", "insights-web/config/environment"], function (_exports, _bsDatetimepicker, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _bsDatetimepicker.default.extend({
    config: _environment.default['ember-cli-bootstrap-datetimepicker']
  });
});