define("insights-web/templates/components/select-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wtNq4EOv",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"select\"],[14,0,\"form-control \"],[15,\"onchange\",[30,[36,2],[[32,0],\"selectItem\"],[[\"value\"],[\"target.value\"]]]],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"option\"],[15,2,[32,1]],[15,\"selected\",[30,[36,1],[[35,0],[32,1]],null]],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selectedItem\",\"eq\",\"action\",\"items\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/select-box.hbs"
    }
  });
});