define("insights-web/templates/components/widget-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ODyKpnLT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,5,\"width:100%\"],[14,0,\"realtime-widget\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"h3 widget-title\"],[15,5,[31,[\"font-size:\",[34,2]]]],[12],[2,\"\\n\"],[6,[37,3],[[35,0,[\"useCustomLabel\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[35,0,[\"customLabel\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,1],[[35,0,[\"title\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"widget-content \"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"calculated-content\"],[15,5,[31,[\"font-size:\",[34,4]]]],[12],[2,\"\\n            \"],[1,[34,5]],[2,\"\\n        \"],[13],[2,\"\\n            \\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"configuration\",\"formattimezone\",\"titlesize\",\"if\",\"contentsize\",\"data\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/widget-clock.hbs"
    }
  });
});