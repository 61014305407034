define("insights-web/templates/conversationquery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "T1m3vX+K",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\\n\"],[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"call-detail-query\"]}",
    "meta": {
      "moduleName": "insights-web/templates/conversationquery.hbs"
    }
  });
});