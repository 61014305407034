define("insights-web/services/featuretoggle-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    requestService: Ember.inject.service(),
    features: {
      "observationchart": ["e89cee8c-24d8-43ad-9464-520f6b20fa1f",
      //PureInsights
      "4136c7bf-96e9-4652-b07c-d981b839b04f"],
      //Vivint        
      "dashboardplus": ["e89cee8c-24d8-43ad-9464-520f6b20fa1f",
      //PureInsights
      // "f657a486-0a31-413f-bcf9-f453b0b3d6db", //PCN
      "93a01c88-1b55-424d-bd2b-47e227a524a8",
      // Genesis Energy Dev
      "56170b71-e293-4f7f-8f33-dbe7a5c12b85",
      // Genesis Energy Prod
      "2332acaa-1795-44e7-8dfb-0499e3dc8c95",
      // Frontline Managed Services
      // "88d1a56f-a5dc-44dd-aef2-d74c062e3ae7", // NCR
      // "8c0a0bb5-19be-4c1d-b454-df3639267148", // ncr atmco
      "f22d7ddb-18bf-46b6-90c9-4f8c99c14d67" // MnTProduction
      // "838ac5aa-bad0-4f8f-ac06-920ae761babd", //NCRvoyix
      ]
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set("organizationId", this.get("requestService").me.organization.id);
    },
    featureEnabled: function featureEnabled(featureName) {
      var features = this.get("features");
      if (!features[featureName]) {
        return true;
      }
      var orgId = this.get("organizationId");
      return features[featureName].indexOf(orgId) > -1;
    }
  });
});