define("insights-web/templates/components/stat-grid-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Vh37mESX",
    "block": "{\"symbols\":[\"value\"],\"statements\":[[10,\"div\"],[14,0,\"row statgridrow\"],[12],[2,\" \\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"   \\n    \"],[10,\"div\"],[15,0,[31,[[32,1,[\"style\"]],\" statgridcol\"]]],[14,5,\"vertical-align:bottom\"],[12],[2,\"\\n\\n\"],[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"b\"],[12],[1,[32,1,[\"text\"]]],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,1,[\"text\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"isHeader\",\"if\",\"values\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/stat-grid-row.hbs"
    }
  });
});