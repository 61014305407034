define("insights-web/templates/components/queue-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IKtQzHrm",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],null,[[\"title\",\"description\"],[[35,5],[35,4]]],[[\"default\"],[{\"statements\":[[1,[30,[36,3],null,[[\"availableItems\",\"selectedItems\",\"title\",\"isLoading\"],[[35,2],[35,1],\"\",[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isLoading\",\"selectedQueueNames\",\"availableQueueNames\",\"two-col-selector\",\"description\",\"title\",\"query-panel\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/queue-selector.hbs"
    }
  });
});