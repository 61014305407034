define("insights-web/templates/components/debug-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "T7nQhzd8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"br\"],[12],[13],[2,\"\\n\"],[11,\"button\"],[24,0,\"btn btn-link\"],[4,[38,0],[[32,0],\"downloadDebug\"],null],[12],[2,\"Click to \"],[10,\"br\"],[12],[13],[2,\"download Debug\"],[10,\"br\"],[12],[13],[2,\" Data\"],[13]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "insights-web/templates/components/debug-widget.hbs"
    }
  });
});