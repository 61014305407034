define("insights-web/templates/mypermissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UGvDbep9",
    "block": "{\"symbols\":[\"queue\",\"permission\"],\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\\n\"],[10,\"h1\"],[12],[2,\"Permissions\"],[13],[2,\"\\n\"],[10,\"ul\"],[12],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"li\"],[12],[2,\"\\n    \"],[1,[32,2]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[13],[2,\"\\n\\n\"],[10,\"h1\"],[12],[2,\"Queues\"],[13],[2,\"\\n\"],[10,\"table\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n            \"],[1,[32,1,[\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n            \"],[1,[32,1,[\"id\"]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"permissions\",\"-track-array\",\"each\",\"queues\"]}",
    "meta": {
      "moduleName": "insights-web/templates/mypermissions.hbs"
    }
  });
});