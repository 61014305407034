define("insights-web/components/sankey-diagram", ["exports", "ember-google-charts/components/sankey-diagram"], function (_exports, _sankeyDiagram) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sankeyDiagram.default;
    }
  });
});