define("insights-web/components/database-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    requestService: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    requiredPermissions: [{
      domain: "analytics",
      entity: "conversationDetail",
      action: "view"
    }, {
      domain: "analytics",
      entity: "conversationAggregate",
      action: "view"
    }, {
      domain: "analytics",
      entity: "conversationProperties",
      action: "view"
    }, {
      domain: "analytics",
      entity: "userAggregate",
      action: "view"
    }, {
      domain: "analytics",
      entity: "userObservation",
      action: "view"
    }, {
      domain: "analytics",
      entity: "userDetail",
      action: "view"
    }, {
      domain: "routing",
      entity: "queue",
      action: "view"
    }, {
      domain: "routing",
      entity: "wrapupCode",
      action: "view"
    }],
    roles: [],
    init: function init() {
      this._super.apply(this, arguments);
      var requiredPermissions = this.get("requiredPermissions");
      var self = this;
      var requestService = self.get("requestService");
      requestService.getResource('/purecloud/myauthorization').then(function (authorization) {
        self.set("hasOauthCreate", authorization.hasOauthCreate);
        self.set("doesNotHaveOauthCreate", !authorization.hasOauthCreate);
        self.set("hasRoleView", authorization.hasRoleView);
        if (!authorization.hasRoleView) {
          self.set("roles", authorization.roles);
        } else {
          authorization.roles.forEach(function (role) {
            for (var y = 0; y < requiredPermissions.length; y++) {
              var permission = requiredPermissions[y];
              var wasFound = false;
              for (var x = 0; x < role.permissionPolicies.length; x++) {
                var policy = role.permissionPolicies[x];
                if (policy.domain === permission.domain && (policy.entityName === permission.entity || policy.entityName === "*") && (policy.actionSet.indexOf(permission.action) > -1 || policy.actionSet.indexOf("*") > -1)) {
                  wasFound = true;
                }
              }
              if (!wasFound) {
                return;
              }
            }
            self.roles.pushObject(role);
          });
        }
      });
      requestService.getResource('/dbsync/config').then(function (config) {
        self.set("config", config);
      });
    },
    actions: {
      createClient: function createClient() {
        var self = this;
        var requestService = this.get("requestService");
        var requestBody = {
          roleId: this.selectedRole
        };
        requestService.postResource('/dbsync/createclient', requestBody).then(function () {
          self.get('notifications').success('Saved successfully', {
            autoClear: true,
            clearDuration: 1200
          });
          requestService.getResource('/dbsync/config').then(function (config) {
            self.set("config", config);
          });
        }).catch(function (err) {
          Ember.Logger.error(err);
          self.get("analyticsService").exception(err);
          self.get('notifications').error('Unable to save dashboard settings.', {
            autoClear: true,
            clearDuration: 1200
          });
        });
      }
    }
  });
});