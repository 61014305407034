define("insights-web/helpers/timezoneformat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.timezoneFormat = timezoneFormat;
  function timezoneFormat(params) {
    var value = params[0];
    return value.replace("/", " / ").replace("_", " ");
  }
  var _default = _exports.default = Ember.Helper.helper(timezoneFormat);
});