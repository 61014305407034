define("insights-web/services/queue-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    requestService: Ember.inject.service(),
    queueMap: null,
    queueIdToNameMap: null,
    queueList: null,
    notifications: Ember.inject.service('notification-messages'),
    updateQueuesFromPureCloud: function updateQueuesFromPureCloud() {
      var _this = this;
      var self = this;
      this.get("requestService").getResource("/purecloud/queues").then(function (queues) {
        var map = {};
        var queueIdToNameMap = {};
        for (var x = 0; x < queues.length; x++) {
          map[queues[x].name] = queues[x].id;
          queueIdToNameMap[queues[x].id] = queues[x].name;
        }
        self.set("queueIdToNameMap", queueIdToNameMap);
        self.set("queueMap", map);
        self.set("queueList", queues);
      }).catch(function (err) {
        _this.get("notifications").error('Unable to get queues:' + err.status + " " + err.payload.error, {
          autoClear: true,
          clearDuration: 1200
        });
        console.error(err);
        Ember.run.later(function () {
          self.updateQueuesFromPureCloud();
        }, 15000);
        Ember.Logger.error(err);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.updateQueuesFromPureCloud();
    }
  });
});